<template>

    <div class="a33">

        <el-form :model="multiDefaultValueForm"

                 ref="multiDefaultValueForm" size="mini" label-width="100px">

            <!--:label="'默认值' + index"-->



            <el-form-item v-if="type=='TB'"

                          v-for="(item, index) in multiDefaultValueForm.items"

                          :label="item.label"

                          :key="item.key"

                          :rules="itemRules"

                          :prop="'items.' + index + '.value'"

            >

                <!--:rules="{required: false, message: '默认值不能为空', trigger: 'blur'}"-->

                <el-row>

                    <el-col :span="20">

                        <el-input v-if="controlType=='NTX'" type="number" v-model="item.value"></el-input>

                        <el-input v-else v-model="item.value"></el-input>

                    </el-col>

                    <el-col :span="4">

                        <!--<el-button icon="el-icon-delete" circle @click="removeDomain(item)"-->

                        <!--style="margin-left:10px;"></el-button>-->

                        <!--<i @click="removeDomain(item)" style="margin-left:10px;color:#787878;"-->

                        <!--class="el-icon-delete"></i>-->

                    </el-col>

                </el-row>

            </el-form-item>

            <el-form-item>

                <!--<el-button type="primary" @click="saveMultidefaultValueForm('multiDefaultValueForm')">提交</el-button>-->

                <!--<el-button @click="addItem">增加</el-button>-->

                <!--<el-button @click="resetForm('multiDefaultValueForm')">重置</el-button>-->

            </el-form-item>

        </el-form>

    </div>

</template>



<script>

    export default {

        props: [

            'type',

            'repeatNum',

            'defaultValue',

            'controlType',

            'numConfig',

        ],

        computed: {

            itemRules() {

                let rules = [

                    {required: false, message: ``, trigger: ['blur', 'change']},

                ];

                if (this.controlType == 'NTX')

                    rules.push(this.ntxRule);

                return rules;

            }

        },

        data() {

            return {

                multiNum: 1,

                multiDefaultValueForm: {

                    items: [

                        // {value: '', label: '默认值1'},

                    ]

                },

                ntxRule: {

                    validator: (rule, value, callback) => {

                        let np = /^(-)?\d+(\.\d+)?$/

                        if (!np)

                            callback(new Error('数字输入有误'));

                        let nc = JSON.parse(this.numConfig);

                        if (nc) {

                            if (nc.allowDecimal == 0) {

                                 if (value.indexOf('.') >= 0)

                                    callback(new Error('不允许输入小数'));

                            } else {

                                if (value.toString().indexOf('.') >= 0) {

                                    let s = value.toString().split(".")[1].length;

                                    if (s > nc.decimalDigits)

                                        callback(new Error('仅允许' + nc.decimalDigits + '位小数'));

                                }

                            }

                            if (nc.scopeValid == 1

                                && (parseFloat(value) > parseFloat(nc.scopeRange_up)

                                    || parseFloat(value) < parseFloat(nc.scopeRange_down)))

                                callback(new Error('数值范围输入有误! 数值范围:{' + nc.scopeRange_down + "," + nc.scopeRange_up + '}'));

                        }

                        callback();

                    },

                    trigger: ['change', 'blur']

                }

            }

        },

        methods: {

            saveMultidefaultValueForm() {

                return this.multiDefaultValueForm.items.length > 0

                    ? this.multiDefaultValueForm.items.map(s => {

                        return (/\s+/g.test(s.value) || s.value == '') ? 'null' : s.value;

                    }).join('#')

                    : '';

            },

            resetForm(formName) {

                this.$refs[formName].resetFields();

            },

            removeDomain(item) {

                var index = this.multiDefaultValueForm.items.indexOf(item)

                if (index !== -1) {

                    this.multiDefaultValueForm.items.splice(index, 1);

                    this.multiNum--;

                }



            },

            addItem() {

                if (this.multiNum >= 40) {

                    this.$message.error("最大支持数量为40!")

                    return false;

                }

                if (this.multiDefaultValueForm.items.length >= 40) {

                    this.$message({type: 'error', message: '最多设置40个默认值', showClose: true, center: true});

                    return false;

                }

                this.multiDefaultValueForm.items.push({

                    value: '',

                    key: Date.now()

                });

                this.multiNum++;

            },

            clean() {

                this.multiDefaultValueForm.items.length = 0;

            },

            init(defaultValue) {

                this.clean();

                this.multiNum = this.repeatNum;

                this.repeatNum = this.repeatNum || 1;
                let varr = []
                if (this.controlType != 'LB' && this.controlType != 'SUF') {
                    try {
                        varr = this.controlType != 'CB' && this.controlType != 'ADSEL'

                        ? (Array.isArray(JSON.parse(defaultValue))?JSON.parse(defaultValue):[])

                        : typeof defaultValue == 'object'

                            ? (Array.isArray(JSON.parse(defaultValue))?JSON.parse(defaultValue):[])

                            : [];
                    } catch (error) {
                         varr = []
                    }
                    


                    for (let i = 1; i <= this.repeatNum; i++)

                        this.multiDefaultValueForm.items.push({

                            value: i <= varr.length

                                ? varr[i - 1] == 'null' ? '' : varr[i - 1]

                                : '',


                        });

                }

            }

        },

        mounted() {



            // this.init();

        }



    }

</script>