<template>
    <div class="calculate-container">
        <div class="selector-container">
            <el-form v-model="calForm" label-position="left" label-width="70px" size="small">
                <el-row v-if="itemControlType=='NTX'">
                    <el-col :span="11">
                        <el-form-item label="计算类型">
                            <template>
                                <el-radio-group v-model="calForm.type" @change="calculateTypeChange">
                                    <el-radio v-for="(e ,i ) in selector.calculateType" :key="i"
                                              :value="e.value" :label="e.value">
                                        {{e.text}}
                                    </el-radio>
                                </el-radio-group>
                            </template>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11" :offset="2" v-if="calForm.type==2">
                        <el-form-item label="单位">
                            <el-select
                                    @change="onDateUnitChange"
                                    style="width: 100%" v-model="calForm.dateUnit" placeholder="请选择" size="mini">
                                <el-option v-for="ee in selector.calculateDateUnit"
                                           :key="ee.label" :label="ee.label"
                                           :value="ee.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="padding-top: 1em">
                    <el-col :span="11">
                        <el-form-item label="选择访视">
                            <el-select style="width: 100%"
                                       v-model="calForm.parentSectionCode" filterable
                                       collapse-tags
                                       placeholder="选择访视"
                                       @change="sectionChangeGetForm">
                                <el-option v-for="ee in sectionList"
                                           :key="ee.code" :label="ee.name"
                                           :value="ee.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="11" :offset="2">
                        <el-form-item label="选择表单">
                            <el-select style="width: 100%"
                                       v-model="calForm.sectionCode" filterable
                                       collapse-tags
                                       placeholder="选择表单"
                                       @change="formChangeGetItem">
                                <el-option
                                        v-for="ee in formList"
                                        :key="ee.code" :label="ee.name"
                                        
                                        :value="ee.code"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- 标签 -->
        <div class="title">
            <p>选择变量</p>
        </div>
        <!-- <el-scrollbar style="height:80px"> -->
        <div class="btn-box">
            <!--有了 其他计算符,则不再进行时间运算-->
            <span contenteditable="false" v-for="(e,i) in elements" :key="i"
                  class="btn-box-ele" @click="elementClick(e,'item')">{{e.ddDisplayNameEn}}
                </span>
        </div>
        <!-- </el-scrollbar> -->
        <div class="element-container">
            <div class="operator-box">
                <span contenteditable="false" v-for="(e,i) in number" :key="e" class="operator-ele"
                      :class="{'iDisabled':e=='.'&&onlyBascOper}"
                      @click="elementClick(e,'num')">{{e}}
                </span>
                <span contenteditable="false" v-for="(e,i) in operator" :key="e" class="operator-ele"
                      @click="elementClick(e,'oper')">{{e}}
                </span>
                <span v-show="calcuatorType=='logic'" contenteditable="false" v-for="(e,i) in logicOper" :key="e"
                      class="operator-ele"
                      :class="{'iDisabled':calcuatorType!='logic'}"
                      @click="elementClick(e,'oper')">{{e}}
                </span>
                <span contenteditable="false" v-for="(e,i) in funOper" :key="e" class="operator-ele"
                      @click="delEle(e)">{{e}}
                </span>
            </div>
        </div>
        <span class="value">{{currentItem.ddDisplayNameEn}}变量的值为</span>
        <div class="calculate-rule-container">
            <div contenteditable="false" class="calculate-rule-box" style="ime-mode:disabled"
                 v-html="calculateHtml" id="calculateHtml">
            </div>
        </div>

        <div style="text-align: right">
            <el-button @click="cancelCalculateExp" size="small">取消</el-button>
            <el-button :disabled="calculateArr.length==0" type="primary" @click="save" size="small">保存</el-button>
        </div>

    </div>
</template>

<script>
    import {getSection, getSectionForm, getVariable} from "@/api/formEdit";
    import globalConst from '../utils/globalConst'
    import {SelectorDefine} from '../utils/SelectorDefine'

    export default {
        props: [
            'researchCode',
            'parentCode',
            'sectionCode',
            'sectionType',
            'ddCode',
            'itemControlTypeParam',//计算结果的控件类型 NTX DP TP DTP,
            'exp',//表达式,录入是 计算使用
            'expArr',//表达式的element数组,回显使用
            'calcuatorType',//计算器类型 计算cal/核查logic
            'currentItem',
            'type',
            'dateUnit',
        ],
        methods: {
            save() {
                if (this.saveCalculateExp()) {
                    let calObj = {
                        calculateExp: this.calculateExp.join('，'),
                        calculateArrJson: JSON.stringify(this.calculateArr),
                        calculateDisplay: this.calculateDisplay,
                        itemControlType: this.itemControlType,
                        type: this.calForm.type,
                        dateUnit: this.calForm.dateUnit,
                    }
                    console.log('保存计算', calObj);
                    this.$emit("saveCalculateExp", calObj);
                }
                else
                    return false;
            },
            saveCalculateExp() {
                if (this.leftBracketNum == 1)
                    return this.$errInfo("表达式有误,括号不匹配");

                if (this.preElement.code == 'oper' && !(
                    this.preElement.element == ')'
                    || this.preElement.element == globalConst.calculatorSymbol.power2
                    || this.preElement.element == globalConst.calculatorSymbol.power3))
                    return this.$errInfo("表达式不完整");

                if (this.calculateArr.length == 1) {
                    let e = this.calculateArr[0];
                    if (e.code == 'item') {
                        if (this.itemControlType == 'NTX' && this.numControlType.indexOf(e.controlType) < 0)
                            return this.$errInfo("数字类型标签赋值表达式类型错误");
                        if (this.itemControlType == 'TP' && e.controlType != 'TP')
                            return this.$errInfo("时间类型标签赋值表达式类型错误");
                        if ((this.itemControlType == 'DP' || this.itemControlType == 'DTP')
                            && (e.controlType != 'DP' || e.controlType != 'DTP'))
                            return this.$errInfo("日期类型标签赋值表达式类型错误");
                    }
                    if (e.code == 'oper')
                        return this.$errInfo("表达式不完整,不能只包含符号");

                }
                let expArr = this.preSave();
                let suffixArr = this.toSuffixExp(expArr);
                if (suffixArr.length == 1) {
                    this.calculateExp = suffixArr;
                    return true;
                }
                let preResultType = this.expCk(suffixArr);
                // console.log('后缀表达式----', suffixArr, '计算结果类型', preResultType);
                if (preResultType == false) {
                    return false;
                }

                if (preResultType != this.itemControlType)
                    return this.$errInfo("表达式有误,计算结果类型与期望不匹配")
                this.calculateExp = suffixArr;
                return true;
            },
            preSave() {

                let expArr = [];
                //处理赋值操作
                let num = '';
                for (let i = 0; i < this.calculateArr.length; i++) {
                    const c = this.calculateArr[i];
                    if ('num' == c.code) {
                        num += c.element;
                        this.calculateDisplay += c.element;
                    } else {
                        if (num != '')
                            expArr.push(num);
                        num = '';
                    }

                    if ('item' == c.code) {
                        expArr.push(c.ddCode);
                        this.calculateDisplay += c.element.ddDisplayNameEn + ' ';
                    }

                    if ('oper' == c.code) {
                        expArr.push(c.element);
                        this.calculateDisplay += c.element + ' ';
                    }
                }
                //如果循环中,数字是在最后面,则在循环中会丢失这一步的操作,导致少了最后的数字
                if (num != '')
                    expArr.push(num);
                num = '';
                // console.log('初步梳理数组', expArr);
                expArr = this.handelBracket(expArr);
                // console.log('处理括号', expArr);
                expArr = this.handleExtr(expArr);
                // console.log('数字计算处理开方', expArr);
                expArr = this.handeExp(expArr);
                // console.log('数字计算处理苹果放---', expArr);


                for (let i = 0; i < expArr.length; i++) {
                    if (expArr[i].startsWith('(')) {
                        let subArr = this.getSubExpArr(expArr[i]);
                        subArr.unshift("(")
                        subArr.push(")")
                        subArr.unshift(i, 1);
                        Array.prototype.splice.apply(expArr, subArr);
                    }
                }
                // console.log('处理了字计算---', expArr);
                return expArr;
            },

            expCk(expArray) {
                // console.log('转换的后缀表达式', expArray);
                let itemArr = this.calculateArr.filter(s => s.code == 'item');
                let stack = [];
                let top = -1;
                let preResultType = '';
                for (let i in expArray) {
                    let ele = expArray[i];
                    // console.log('ele ', ele);
                    if (this.isOper(ele)) {
                        let var1 = stack[top--];
                        let var2 = stack[top--];
                        let type2 = this.getControlType(itemArr, var2);
                        let type1 = this.getControlType(itemArr, var1);
                        if (!type1 || !type2)
                            return this.$errInfo('type2 type1 的类型有空值');
                        preResultType = this.getTempResultType(type2, type1, ele);
                        if (preResultType == false)
                            return false;
                        if (preResultType == 'NTX')
                            stack[++top] = "1";

                        if (preResultType == 'TP')
                            stack[++top] = "00:00";

                        if (preResultType == 'DTP' || preResultType == 'DP')
                            stack[++top] = "1111-11-11 00:00:00";

                        if (preResultType == '' || preResultType == false)
                            return this.$errInfo('表达式有误');
                        console.log(expArray, type2, ele, type1, '最终预算结果的类型', preResultType);

                    } else {
                        stack[++top] = ele;
                    }
                }
                return preResultType;
            },

            getTempResultType(type2, type1, oper) {

                // 运算顺序 type2 oper type1
                if (this.numControlType.indexOf(type2) >= 0) {
                    if (this.numControlType.indexOf(type1) >= 0)
                        return 'NTX';
                    if (type1 == 'TP' && oper == '+')
                        return 'TP';

                    if ((type1 == 'DTP' || type1 == 'DP') && oper == '+')
                        return 'DP';
                    return this.$errInfo('数字计算表达式有误,请认真检查！');
                } else if (type2 == 'TP') {
                    if (type1 == 'NTX' && (oper == '+' || oper == '-'))
                        return 'TP';
                    if (type1 == 'TP' && oper == '-')
                        return 'NTX';
                    return this.$errInfo('对时间的操作有误,仅允许时间减时间、时间加减数字操作！');
                } else if (type2 == 'DP' || type2 == 'DTP') {
                    if ((type1 == 'DP' || type1 == 'DTP') && oper == '-')
                        return 'NTX';
                    if (type1 == 'NTX' && (oper == '-' || oper == '+'))
                        return type2;
                    return this.$errInfo('对日期的操作有误,仅允许日期减日期、日期加减数字操作！');
                } else
                    return this.$errInfo('表达式有误,请仔细检查！');

            },

            getControlType(itemArr, e) {
                if (this.isSingleDdcode(e)) {
                    let ddCode = e;
                    for (let i = 0; i < itemArr.length; i++) {
                        let ele = itemArr[i];
                        if (ele.element.ddCode == ddCode)
                            return ele.controlType;
                    }
                } else if (this.isNum(e)) {
                    return 'NTX'
                } else if (this.isTime(e))
                    return 'TP';
                else if (this.isDate(e))
                    return 'DP'
                else if (this.isDatetime(e))
                    return 'DTP';
                else if ('' + e.startsWith('Math'))
                    return 'NTX';
                return '';
            },
            toSuffixExp(expArr) {
                let bracket = false;
                let suffixExpArr = []
                let stack = [];
                let top = -1;
                for (let i in expArr) {
                    let ele = expArr[i];
                    if (ele.startsWith("Math"))
                        suffixExpArr.push(ele + '');

                    if (this.isSingleDdcode(ele))
                        suffixExpArr.push(ele);

                    if (this.isNum(ele))
                        suffixExpArr.push(ele);

                    if (this.basicOper.indexOf(ele) >= 0) {
                        //     //stack中的操作符优先级高,出栈
                        let r = this.operPriorityComp(ele, stack[top]);
                        if (top > -1 && r == -1) {
                            if (bracket) {
                                suffixExpArr.push(stack[top--]);
                                stack[++top] = ele;
                            } else {
                                while (top > -1)
                                    suffixExpArr.push(stack[top--]);
                                stack[++top] = ele;
                            }
                        } else if (top > -1 && r == 0) {
                            suffixExpArr.push(stack[top--]);
                            stack[++top] = ele;
                        } else
                            stack[++top] = ele;

                    }
                    if (ele == '(') {
                        bracket = true;
                        stack[++top] = ele;
                    }
                    if (ele == ')') {
                        let t = '';
                        while (top > -1 && (t = stack[top--]) != '(')
                            if (t != ')')
                                suffixExpArr.push(t);
                        bracket = false;
                    }
                    if (ele.startsWith('(') && ele != '(') {
                        let subCalArr = this.getSubExpArr(ele);
                        let subCalArr2 = this.toSuffixExp(subCalArr);
                        subCalArr2.unshift(1, 0);
                        Array.prototype.splice.apply(suffixExpArr, subCalArr2);
                    }
                }

                while (top > -1)
                    suffixExpArr.push(stack[top--]);
                return suffixExpArr;
            },

            getSubExpArr(ele) {
                ele = ele.slice(1, ele.length - 1);
                let subCalArr = [];
                let tnum = '';
                let isMathFun = false;
                let mstr = '';
                let mfunBracketNum = 0;
                for (let i = 0; i < ele.length; i++) {
                    let code = ele.charCodeAt(i);
                    if (ele[i] == 'M') {
                        mfunBracketNum = 1;
                        isMathFun = true;
                        mstr = ele.substr(i, 5);
                        i += 5;
                        if (ele[i] == 'p') {
                            mstr += ele.substr(i, 4);
                            i += 4;
                        }
                        if (ele[i] == 's') {
                            mstr += 5;
                            mstr += ele.substr(i, 5);
                            i += 5;
                        }
                    }

                    if (isMathFun) {
                        mstr += ele[i];
                        if (ele[i] == '(')
                            mfunBracketNum++;

                        if (ele[i] == ')')
                            mfunBracketNum--;

                        if (mfunBracketNum == 0) {
                            isMathFun = false;
                            subCalArr.push(mstr);
                            i++;
                        }
                    }
                    //ddcode 都是以字母开头的
                    if ((code >= 97 && code <= 122) && !isMathFun) {
                        let ddCode = ele.substr(i, 16);
                        if (this.isSingleDdcode(ddCode)) {
                            if (tnum != '')
                                subCalArr.push(tnum);
                            tnum = '';
                            i += 16;
                            subCalArr.push(ddCode)
                        }
                    }
                    if (this.basicOper.indexOf(ele[i]) >= 0 && !isMathFun) {
                        if (tnum != '')
                            subCalArr.push(tnum);
                        tnum = '';
                        subCalArr.push(ele[i]);
                    }
                    if (this.isNum(ele[i]) && !isMathFun) {
                        tnum += ele[i];
                    }

                }
                if (tnum != '')
                    subCalArr.push(tnum);
                return subCalArr;
            },
            handelBracket(expArr) {
                let exp = [];
                for (let i = 0; i < expArr.length; i++) {
                    const e = expArr[i];
                    if (e == '(') {
                        let a = [];
                        for (let j = i + 1; j < expArr.length; j++) {
                            const ee = expArr[j];
                            i++;
                            if (ee == '(') {
                                a.push("(")
                            } else if (ee == ")") {
                                exp.push(a);
                                break;
                            } else
                                a.push(ee);
                        }
                    } else
                        exp.push(e);
                }
                return exp;
            },
            //处理平方 立方 ,返回数组中的平方操作
            handeExp(expArr, isSub) {
                let s = '';
                let sarr = [];
                if (isSub)
                    s += '(';
                for (let i = 0; i < expArr.length; i++) {
                    const e = expArr[i];
                    //看后面是不是 平方 立方
                    if (i + 1 < expArr.length && (expArr[i + 1] == globalConst.calculatorSymbol.power2 || expArr[i + 1] == globalConst.calculatorSymbol.power3)) {
                        s += "Math.pow(" + e + ", '" + expArr[i + 1] + "'== '" + globalConst.calculatorSymbol.power2 + "' ? 2 : 3)";
                        sarr.push("Math.pow(" + e + ", '" + expArr[i + 1] + "'== '" + globalConst.calculatorSymbol.power2 + "' ? 2 : 3)")
                        i++;//if里已经看了下一个元素,并且进行了操作,所以循环时要跳过
                    } else {
                        s += e;
                        sarr.push(e);
                    }
                }
                if (isSub)
                    s += ")";
                if (!isSub)
                    return sarr;
                return s;
            },
            isSingleDdcode(n) {
                return /[a-z0-9]{16}$/.test(n);
            },
            isNum(n) {
                return /^(-)?\d+(\.\d+)?$/.test(n);
            },
            isTime(n) {
                return new RegExp(/^(?:\d{2}):\d{2}$/).test(n);
            },
            isDate(n) {
                return new RegExp(/\b(\d{4})\/(\d{2})\/(\d{2})\b/).test(n);
            },
            isDatetime(n) {
                return new RegExp(/\b(\d{4})-(\d{2})-(\d{2})\b (?:\d{2}):\d{2}:\d{2}$/).test(n);
            },
            //处理开方
            handleExtr(expArr) {
                let exp = [];
                for (let i = 0; i < expArr.length; i++) {
                    const e = expArr[i];
                    if (e == globalConst.calculatorSymbol.genhao) {
                        if (typeof expArr[i + 1] == 'object') {
                            let s = this.handeExp(expArr[i + 1], true);
                            exp.push("Math.sqrt(" + s + ")")
                        } else
                            exp.push("Math.sqrt(" + expArr[i + 1] + ")")
                        i++;
                    } else if (typeof e == 'object') {
                        exp.push(this.handeExp(e, true));
                    } else
                        exp.push(e);
                }
                return exp;
            },
            operPriorityComp(o1, o2) {
                if (!(this.basicOper.indexOf(o1) >= 0 && this.basicOper.indexOf(o2) >= 0))
                    return -2;
                let r = 0;
                let oo1 = ['+', '-'];
                let oo2 = ['*', '/'];
                if ((oo1.indexOf(o1) >= 0 && oo1.indexOf(o2) >= 0)
                    || (oo2.indexOf(o1) >= 0 && oo2.indexOf(o2) >= 0))
                    return 0;
                if (oo2.indexOf(o1) >= 0 && oo1.indexOf(o2) >= 0)
                    return 1;
                if (oo2.indexOf(o2) >= 0 && oo1.indexOf(o1) >= 0)
                    return -1;
                return -2;
            },
            isOper(ele) {
                return this.basicOper.indexOf(ele) >= 0;
            },

            getElementCloneHtml(code, element) {
                if ('item' == code)
                    return this.elementClone(element.ddDisplayNameEn);
                if ('oper' == code)
                    return this.operatorClone(element, '0.2em');
                if ('num' == code)
                    return this.operatorClone(element, '0em');
            },

            getSection() {
                const params = {
                    registryEntityCode: this.researchCode,
                    versionCode: this.$localData('get', this.$gconst.RESEARCH_VERSION_CODE, '', false),
                };
                getSection(params).then(r => {
                    this.sectionList = r.data.data
                });
            },
            delEle(e) {

                this.calculateDisplay = '';
                //清空
                if (globalConst.calculatorSymbol.clearBtn == e) {
                    this.calculateExp = [];
                    this.calculateArr.splice(0, this.calculateArr.length);
                    this.calculateHtml = '';
                    this.calculateHtmlArr.splice(0, this.calculateHtmlArr.length);
                    this.preElement = {code: '', controlType: '', ddCode: '', element: null};
                    this.hasNumDPoint = false;
                    this.leftBracketNum = 0;
                    this.tempNumExp = '';

                }
                //删除上一个元素
                if (globalConst.calculatorSymbol.deleteBtn == e && this.calculateArr.length > 0) {
                    let de = this.calculateArr.pop();
                    if (')' == de.element)
                        this.leftBracketNum++;
                    if ('(' == de.element)
                        this.leftBracketNum--;
                    if ('.' == de.element)
                        this.hasNumDPoint = false;

                    /*
                        删除的时候,看第一个item的类型,tp,dp,dtp类型之间不能混合计算,
                    */
                    this.preElement = this.calculateArr.length > 0
                        ? this.calculateArr[this.calculateArr.length - 1]
                        : {code: '', controlType: '', element: null, ddCode: ''};
                    this.calculateHtmlArr.pop();
                    this.calculateHtml = this.calculateHtmlArr.join('');
                }
            },

            calPreCk(e) {
                let p = this.preElement;
                // console.log(p.code, p.element, "前一个表单标签", e.code, e.element);
                //e是表达式中的第一个元素
                if (p.element == null) {
                    //以数字开头,不允许0和小数点开头
                    if ('num' == e.code) {
                        // if (e.element == '0')
                        //     return this.$errInfo('表达式不允许以数字0开头');
                        if (e.element == '.')
                            return this.$errInfo('表达式不允许以小数点开头');

                    }
                    //以表单标签开头 ,暂时不用做限制
                    if ('item' == e.code) {

                    }
                    // 以符号开头 ,仅允许 根号 减号(负号) 左括号 开头
                    if ('oper' == e.code) {
                        if (e.element == '(')
                            this.leftBracketNum++;

                        if (e.element != '(' || e.element != '-' || e.element != globalConst.calculatorSymbol.genhao)
                            return this.$errInfo('表达式不允许以此符号开头');
                    }
                }
                //表示不是第一个元素了
                else {
                    /*
                    * 前一个是标签,则后面只能是计算符号 , 匹配的右括号
                    * 数字类型的表单标签后面可以是 平方 立方
                    * */
                    if ('item' == p.code) {
                        if (e.code == 'oper') {
                            //验证右括号匹配
                            if (e.element == ')') {
                                if (this.leftBracketNum == 1)
                                    this.leftBracketNum--;
                                else
                                    return this.$errInfo('表单标签后面的右括号不匹配');
                            }

                            if (e.element == globalConst.calculatorSymbol.genhao)
                                return this.$errInfo('表单标签后面不允许直接使用根号');

                            if (e.element == '(')
                                return this.$errInfo('表单标签后面不允许直接使用左括号');

                            if (this.dtControlType.indexOf(p.controlType) >= 0
                                && (e.element == globalConst.calculatorSymbol.power2
                                    || e.element == globalConst.calculatorSymbol.power3
                                    || e.element == globalConst.calculatorSymbol.genhao))
                                return this.$errInfo('日期时间类型的表单标签后面不允许使用' + e.element + '符号');

                            // if (!((e.element == globalConst.calculatorSymbol.power2
                            //         || e.element == globalConst.calculatorSymbol.power3)
                            //     && (this.numControlType.indexOf(e.controlType) >= 0)
                            // )) {
                            //     return this.$errInfo('仅数字类型的表单标签后面允许使用平方和立方');
                            // }

                        }
                        //不允许连续的标签
                        if (e.code == 'item') {
                            return this.$errInfo('表单标签之间缺少计算符号');
                        }
                        if (e.code == 'num') {
                            return this.$errInfo('表单标签后面不能直接使用数字符号');
                        }


                    }
                    //前一个标签是数字,后面只能是数字 正确数量的小数点 计算符号 平方 立方 匹配的右括号
                    if ('num' == p.code) {
                        if (e.code == 'item') {
                            return this.$errInfo('数字后面不能直接使用表单标签');
                        }
                        if (e.code == 'num') {
                            //数字中使用了小数点
                            if (e.element == '.') {
                                if (this.hasNumDPoint)
                                    return this.$errInfo('数字中只允许一个小数点');
                                this.hasNumDPoint = true;
                            }
                        }

                        if (e.code == 'oper') {
                            if (p.element == '.')
                                return this.$errInfo('小数点后面不允许使用计算符号');
                            if (e.element == globalConst.calculatorSymbol.genhao)
                                return this.$errInfo('数字后面不允许使用根号');

                            if (e.element == '(')
                                return this.$errInfo('数字后面不允许直接使用左括号');

                            if (e.element == ')') {
                                if (this.leftBracketNum == 1)
                                    this.leftBracketNum--;
                                else
                                    return this.$errInfo('数字后面的右括号不匹配');
                            }
                        }
                    }
                    /*
                     加减乘除 后面可以是左括号 标签 数字
                     平方 立方后面可以是匹配的右括号 加减乘除 立方 平方
                     根号后面可以是数字类型的表单标签
                     */
                    if ('oper' == p.code) {
                        //符号后面是表单标签
                        if (e.code == 'item') {
                            if (p.element == globalConst.calculatorSymbol.genhao
                                && this.numControlType.indexOf(e.controlType) < 0)
                                return this.$errInfo('根号后面的表单标签只允许数字类型');
                            if ((p.element == globalConst.calculatorSymbol.power2
                                || p.element == globalConst.calculatorSymbol.power3))
                                return this.$errInfo('平方立方后面不允许直接使用表单标签');

                            if (p.element == ')')
                                return this.$errInfo('右括号后面不允许直接使用表单标签');
                        }
                        //符号后面是数字类型
                        if (e.code == 'num') {
                            if (e.element == '.')
                                return this.$errInfo('操作符号后面不允许直接使用小数点');
                            if (p.element == globalConst.calculatorSymbol.power2
                                || p.element == globalConst.calculatorSymbol.power3)
                                return this.$errInfo('平方立方后面不允许直接使用数字');
                            if (p.element == ')')
                                return this.$errInfo('右括号后面不允许直接使用数字');
                        }
                        if (e.code == 'oper') {

                            if (p.element == '(') {
                                if (e.element == ')')
                                    return this.$errInfo('括号使用访视错误,表达式中不允许空括号');
                                if (e.element != globalConst.calculatorSymbol.genhao)
                                    return this.$errInfo('括号内子表达式中不允许以此计算符号开头');

                            }
                            if (p.element == ')' && (
                                e.element == globalConst.calculatorSymbol.genhao
                                || e.element == '('
                                || e.element == ')'))
                                return this.$errInfo('右括号后不允许直接使用 ' + e.element + ' 符号');

                            if ((p.element == globalConst.calculatorSymbol.power2
                                || p.element == globalConst.calculatorSymbol.power3)
                                && (e.element == '('
                                    || e.element == globalConst.calculatorSymbol.power2
                                    || e.element == globalConst.calculatorSymbol.power3
                                    || e.element == globalConst.calculatorSymbol.genhao))
                                return this.$errInfo('平方立方后面不允许直接使用左括号,平方立方符号,根号');
                            if (p.element == globalConst.calculatorSymbol.genhao) {
                                if ((this.basicOper.indexOf(e.element) >= 0
                                    || e.element == globalConst.calculatorSymbol.power2
                                    || e.element == globalConst.calculatorSymbol.power3
                                    || e.element == '('
                                    || e.element == ')'))
                                    return this.$errInfo('根号里暂不支持括号计算');
                            }


                            if ((this.basicOper.indexOf(p.element) >= 0)
                                && !(e.element == globalConst.calculatorSymbol.genhao
                                    || e.element == '('))
                                return this.$errInfo('计算符号后面不允许直接使用符号');
                            if (e.element == '(') {
                                if (this.leftBracketNum == 1)
                                    return this.$errInfo('不支持嵌套括号的计算');
                                else
                                    this.leftBracketNum++;
                            }
                            if (e.element == ')') {
                                if (this.leftBracketNum == 1)
                                    this.leftBracketNum--;
                                else
                                    return this.$errInfo('括号不匹配,请检查表达式');
                            }
                        }
                    }
                }
                if (e.code != 'num') {
                    //不是数字的话,重置小数点状态
                    this.hasNumDPoint = false;
                }
                // 除以 0 检查

                return true;
            },
            elementClick(e, code,) {
                let element = {
                    code: code,
                    element: e,
                    ddCode: code == 'item' ? e.ddCode : '',
                    controlType: code == 'item' ? e.controlType : ''
                };

                /*
                    1 先按规则进行进行简单的选择验证
                 */
                if (!this.calPreCk(element))
                    return false;

                /*
                    3 根据element类型进行对应的处理
                 */
                this.calculateHtmlArr.push(this.getElementCloneHtml(code, e));
                this.calculateHtml = this.calculateHtmlArr.join('');
                this.calculateArr.push(element);
                this.preElement = this.calculateArr[this.calculateArr.length - 1];
            },


            cancelCalculateExp() {
                this.$emit('cancelCalculateExp');
            },

            initCalculator() {
                /*
                    加载数据,默认是展示当前表单下的 form标签
                */
                this.getSection();
                this.sectionChangeGetForm(this.parentCode, true);
                this.formChangeGetItem(this.sectionCode, true);
                /*
                    表达式回显 需要设置四个属性
                */
                if (this.expArr) {

                    this.calculateArr = this.expArr;
                    console.log(this.calculateArr);
                    for (let i = 0; i < this.calculateArr.length; i++) {
                        const c = this.calculateArr [i];
                        this.calculateHtmlArr.push(this.getElementCloneHtml(c.code, c.element));
                    }
                    if (this.calculateArr.length) {
                        this.calculateHtml = this.calculateHtmlArr.join('');
                        this.preElement = this.calculateArr[this.calculateArr.length - 1];
                    }
                }
            },
            calculateTypeChange(val, isInit) {

                if (!isInit)
                    this.delEle(globalConst.calculatorSymbol.clearBtn);


                if (this.itemControlType == 'NTX') {
                    if (val == 1) {
                        this.elements = this.elementsCopy.filter(e => (this.numControlType.indexOf(e.controlType) >= 0));
                    }
                    if (val == 2) {
                        this.elements = this.elementsCopy.filter(e => (this.dtControlType.indexOf(e.controlType) >= 0));
                    }
                } else {
                    if (this.itemControlType == 'TP')
                        this.elements = this.elementsCopy.filter(e => (e.controlType == 'TP' || e.controlType == 'NTX'));
                    else if (this.itemControlType == 'DTP' || this.itemControlType == 'DP')
                        this.elements = this.elementsCopy.filter(e => e.controlType != 'TP');
                }

            },
            onDateUnitChange(val) {
                this.delEle(globalConst.calculatorSymbol.clearBtn);
            },
            sectionChangeGetForm(parentCode, isInit) {
                let params = {
                    registryEntityCode: this.researchCode,
                    parentCode: parentCode,
                    sectionType: this.sectionType,
                    sectionCode: this.sectionCode,
                };
                getSectionForm(params).then(r => {
                    this.formList = r.data.data;
                    if (this.formList.length > 0) {
                        if (!isInit) {
                            this.calForm.sectionCode = this.formList[0].code;
                            this.formChangeGetItem(this.calForm.sectionCode)
                        }
                    } else {
                        this.calForm.sectionCode = '';
                        this.elements = {};
                    }
                });
            },
            formChangeGetItem(sectionCode, initCalculator) {

                let params = {
                    registryEntityCode: this.researchCode,
                    sectionCode: sectionCode,
                    ddCode: this.ddCode,
                    type: 'map',
                    isCalculate: 1,
                    groupRepeat: this.sectionType == 'Multiple' ? 1 : 0,
                };

                getVariable(params).then(r => {
                    // console.log('获取计算变量--', r);
                    this.elementsCopy = Object.values(r.data.data) || [];
                    this.elementMap = Object.assign(this.elementMap, r.data.data);
                    this.elements = this.elementsCopy;
                    // if (initCalculator)
                        this.calculateTypeChange(this.type, true);

                })
            },
        },
        

        data() {
            return {
                selector: {
                    dateUnit: [],
                },

                dtControlType: ['TP', "DP", 'DTP'],
                numControlType: ['NTX', 'CB', 'RB', 'SEL_SIG'],
                dtOPer: ["+", "-"],//日期时间计算允许使用的操作符
                funOper: [globalConst.calculatorSymbol.clearBtn, globalConst.calculatorSymbol.deleteBtn],//功能按钮

                preElement: {code: '', controlType: '', ddCode: '', element: null},//当前输入元素的 上一个 元素,用于基本输入检验
                leftBracketNum: 0,//记录左括号的数量,用来实现括号匹配以及禁止括号嵌套
                tempNumExp: '',
                hasNumDPoint: false,//数字中书否包含了小数点
                calculateExp: [],//表达式录入计算使用,
                calculateArr: [],//暂存计算器中的每一个元素
                calculateDisplay: '',//设计页面展示使用,单纯的文本内容
                calculateHtml: '',//页面显示
                calculateHtmlArr: [],//存储每个元素对应的html, 当删除时,删除数组中的元素即可,

                sectionList: [],//
                formList: [],
                elementsCopy: [],
                elements: [],
                elementMap: {},
                calForm: {
                    parentSectionCode: this.parentCode,
                    sectionCode: this.sectionCode,
                    type: this.type,
                    dateUnit: this.dateUnit,
                },
            }
        },
        watch: {
            ddCode:{
                deep:true,
                immediate:true,
                handler: function (n,o){

                    console.log(this.calculateHtml);
                }
            }
        },

        computed: {
            itemControlType() {
                return this.numControlType.indexOf(this.itemControlTypeParam) >= 0
                    ? 'NTX'
                    : this.itemControlTypeParam;
            },
            basicOper() {
                return ["+", "-", "*", "/",];
            },
            logicOper() {
                return ["<", ">", "<=", ">=", "="];//逻辑运算的操作符;
            },
            operator() {
                return ["+", "-", "*", "/", "(", ")", globalConst.calculatorSymbol.power2,
                    globalConst.calculatorSymbol.power3, globalConst.calculatorSymbol.genhao];
            },
            number() {
                return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", '.'];
            },


            /*
            不允许 时间和日期的混合计算
             日期时间和日期的计算结果为天
            时间操作 计算结果为 分钟
            日期操作 计算结果为 天
            * */
            //只允许加减操作
            onlyBascOper() {
                return this.itemControlType != 'NTX';
            },

            elementClone(e) {
                return (e => {
                    return '<span contenteditable="false" ' +
                        'style="display: inline-block;height: 2em;' +
                        'border: #DCDFE6 1px solid;border-radius: 1em;' +
                        'line-height: 2em; user-select: none;text-align: center;cursor: pointer;' +
                        'user-modify:read-only;margin: 0.1em;"' +
                        '> ' + e + '</span>';
                })
            },
            operatorClone(e, margin) {
                return ((e, margin) => {
                    return '<span contenteditable="false" ' +
                        'style="display: inline-block;height: 2em;' +
                        'line-height: 2em;' +
                        'user-modify:read-only;margin:' + margin + ' "' +
                        '> ' + e + '</span>';
                })
            },
        },
        mounted() {
            this.selector = SelectorDefine().FormEdit;
            this.initCalculator();
        }
    }
</script>

<style lang="scss" scoped>
    .title {
        margin-bottom: 10px;
        padding-left: 10px
    }

    /deep/ .el-form-item--small {
        margin-bottom: 0 !important;
    }

    /deep/ .el-dialog__body {
        padding: 10px !important
    }

    .calculate-container {
        // background: rgb(247, 247, 247);
        .btn-box {
            padding: 10px;
            margin: 10px;
            min-height: 80px;
            max-height: 180px;
            border: 1px solid #DCDFE6;
            border-radius: 5px;
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            .btn-box-ele {
                display: inline-block;
                min-width: 80px;
                height: 32px;
                border-radius: 15px;
                background: #eef5fe;
                color: #000;
                line-height: 32px;
                text-align: center;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
            }
        }
    }

    %ele {
        display: inline-block;
        height: 2em;
        border: #DCDFE6 1px solid;
        border-radius: 1em;
        line-height: 2em;
        text-align: center;
        cursor: pointer;
        user-modify: read-only;
        user-select: none;
    }

    .operator-box {
        /*background: white;*/
        display: flex;
        height: auto;
        padding-top: 20px;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        flex-wrap: wrap;
        align-content: flex-start;
        // &:after{
        //     height: 0;
        //     width: 20%;
        //     content: "";
        // }
    }

    .operator-ele {
        // @extend %ele;
        width: 5%;
        height: 38px;
        margin-right: 2%;
        background: #f1f1f1;
        margin-bottom: 20px;
        border-radius: 2px;
        display: inline-block;
        font-size: 12px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        &:hover {
            background: rgba(5, 163, 255, 0.09);
            color: #409eff
        }

    }

    .element-container {
        background: white;
        margin: 10px

    }

    .element-box {
        margin: 30px 0 30px 0;
        height: auto;
        .element-ele {
            @extend %ele;
            margin: 0.6em;

            &:hover {
                background: rgba(5, 163, 255, 0.09);
                color: #409eff
            }
        }
    }

    .calculate-rule-container {
        background: white;
        padding: 10px;
    }

    .value {
        font-size: 14px;
        margin-bottom: 10px;
        padding: 10px;
    }

    .calculate-rule-box {
        width: 100%;
        height: 80px;
        overflow: auto;
        border: #DCDFE6 1px solid;
        border-radius: 4px;
    }

    .selector-container {
        margin: 1.5em;
        background: white;
    }
</style>
<style lang="scss">
    .el-dialog__body {
        padding: 10px !important
    }
</style>
