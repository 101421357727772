<template>
  <div>
    <el-form ref="groupTagForm" :model="groupTagForm" label-width="100px" :rules="groupTagFormRules" size="mini">
      <el-form-item label="组标签" prop="groupName">
        <el-input v-model="groupTagForm.groupName"></el-input>
      </el-form-item>
      <el-form-item label="可重复">
        <el-switch v-model="groupTagForm.isRepeat"></el-switch>
      </el-form-item>
      <el-form-item label="重复次数" v-if="groupTagForm.isRepeat">
        <div class="replate-wrap">
          <div> <el-input @input='onChangeReplaceNum(groupTagForm.groupCount)' @blur='onBlurReplaceNum(groupTagForm.groupCount)' v-model="groupTagForm.groupCount" :max="40" :min='1' maxlength='2' type="number" size="mini" placeholder="请输入重复次数，最大限制40"></el-input>  </div>
          <span v-if="isShowWaring">重复次数，最大限制40</span>
        </div>
      </el-form-item>
      <el-form-item label="允许继续添加" v-if="groupTagForm.isRepeat && groupTagForm.groupCount != 40">
        <el-radio-group v-model="groupTagForm.allow">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否显示" v-if="groupTagForm.isRepeat">
        <el-radio-group v-model="groupTagForm.show">
          <el-radio value="1" :label="1">是</el-radio>
          <el-radio value="0" :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="设置显示条件" v-if="!groupTagForm.show" class="visible-show">
        <el-form :model="currentGroupForm" ref="currentConditionForms" size="small">
          <div v-for="(domain, index) in currentGroupForm.visibleConditionList" :key="domain.key">
            <el-form-item :prop="'visibleConditionList.' + index + '.parentSectionCode'" :rules="visibleConditionRules.parentSectionCode">
              <el-select style="width: 100%" v-model="domain.parentSectionCode" filterable collapse-tags placeholder="选择访视" @focus= "sectionChange($event,index)" @change= "sectionChangeClear($event,index)">
                <el-option v-loading="sectionLoading" v-for="ee in domain.sectionList" :key="ee.code" :label="ee.name" :value="ee.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" :prop="'visibleConditionList.'+index+'.sectionCode'" :rules="visibleConditionRules.sectionCode">
              <el-select style="width: 100%" v-model="domain.sectionCode" filterable @focus= "formChange($event,index)" @change= "formChangeClear($event,index)" collapse-tags placeholder="选择表单">
                <el-option v-loading="formLoading" v-for="ee in domain.formList" :key="ee.code" :label="ee.name" :value="ee.code">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" :prop="'visibleConditionList.'+index+'.parentDdCode'" :rules="visibleConditionRules.parentDdCode">
              <el-select style="width: 100%" v-model="domain.parentDdCode" @focus= "variableChange($event,index)" @change= "variableChangeClear" placeholder="选择变量">
                <el-option v-loading="visibleLoading" v-for="ee in domain.variableList" :key="ee.ddCode" :label="ee.ddName" :value="ee.ddCode"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" :prop="'visibleConditionList.'+index+'.operator'" :rules="visibleConditionRules.operator">
              <el-select style="width: 100%" v-model="domain.operator" collapse-tags placeholder="选择操作">
                <el-option v-for="ee in selector.operatorList" :key="ee.value" :label="ee.label" :value="ee.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="domain.operator=='{}'||domain.operator=='{/}'" label="" :prop="'visibleConditionList.'+index+'.parentValue'" :rules="visibleConditionRules.parentValue">
              <el-input type="textarea" rows="2" placeholder="多个值请使用英文逗号(,)分隔" v-model="domain.parentValue"></el-input>
            </el-form-item>
            <el-form-item v-else label="" :prop="'visibleConditionList.'+index+'.parentValue'" :rules="visibleConditionRules.parentValue">
              <el-input type="input" placeholder="输入值" v-model="domain.parentValue"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-form-item>
      <el-form-item>
        <el-button @click="addGroupLabel('s')" :loading="gbtnLoading" type="primary" size="mini">保存
        </el-button>
        <el-button @click="addGroupLabel('sy')" :loading="gbtnLoading" type="primary" size="mini">保存并应用
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getSection,
  getSectionForm,
  getVariable
} from "@/api/formEdit";
import {
  mapState
} from 'vuex'
export default {
  props: {
    currentFormItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selector: {
      type: Object,
      default: () => {
        return {}
      }
    },
    groupTagForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentGroupForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    gbtnLoading: {
      type:Boolean,
      default: ()=> false
    },
    isRepeat:{
      type: String,
      default:()=> '0'
    },
    ddCode:{
      type: String,
      default:()=> ''
    }
  },
  data() {
    return {
      sectionLoading:false,
      formLoading:false,
      visibleLoading:false,
      sectionForm:{},
      isShowWaring: false,
      groupTagFormRules: {
        groupName: [{
            required: true,
            message: '请输入组标签名称',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 255,
            message: '长度在 1 到 255个字符',
            trigger: 'change'
          }
        ],
      },
      visibleConditionRules: {
        parentSectionCode: [{
          required: true,
          message: '请选择访视',
          trigger: 'change'
        }, ],
        sectionCode: [{
          required: true,
          message: '请选择表单',
          trigger: 'change'
        }, ],
        parentDdCode: [{
          required: true,
          message: '请选择变量',
          trigger: 'change'
        }, ],
        operator: [{
          required: true,
          message: '请选择操作符',
          trigger: 'change'
        }, ],
        parentValue: [{
          required: true,
          message: '请设置条件值',
          trigger: 'change'
        }, ],
      }
    }
  },
  methods: {
    //条件显示 获取访视下的表单
    onGetSection(){
      const params = {
          registryEntityCode: this.$route.query.registryEntityCode,
          versionCode: JSON.parse(localStorage.getItem('versionCode'))
        };
      this.sectionLoading = true
      getSection(params).then(r => {
        this.sectionLoading = false
        this.sectionForm = r.data.data[0]
        this.$set(this.currentGroupForm.visibleConditionList[0],'sectionList',r.data.data)
      }).catch(e => {
        this.sectionLoading = false
        // this.currentFormItem.visibleConditionList[0].sectionList = [];
      })
    },
    onChangeReplaceNum(v) {
      if (parseInt(v) > 40) {
        this.$emit('setGroupTagFormReplaceNum',40)
        this.isShowWaring = true
      } else {
        this.isShowWaring = false
      }
    },
    onBlurReplaceNum() {
      this.isShowWaring = false
    },
    handleIconClick(v) {
      this.dialogVisibleGroup = true
    },
    addGroupLabel(type) {
      this.$refs['groupTagForm'].validate((valid) => {
        console.log(valid);
        if (valid) {
          let showCondition = {}
          if (this.groupTagForm.show == 1) {
            if (type === 's') {
                localStorage.setItem('isOnlySave',0)
              }else {
                localStorage.setItem('isOnlySave',1)
              }
              this.$emit('addGroupLabel',{v:Object.assign(this.groupTagForm,showCondition),cb:()=>{
                localStorage.setItem('groupCount',JSON.stringify(this.groupTagForm.groupCount))
                this.$emit('initGroupTagForm')
              }})
          }else{
            this.$refs['currentConditionForms'].validate((valid) => {
              if (valid) {
                
                if (this.groupTagForm.show == 0) {
                  showCondition.parentSectionCode = this.currentGroupForm.visibleConditionList[0].parentSectionCode
                  showCondition.sectionCode = this.currentGroupForm.visibleConditionList[0].sectionCode
                  showCondition.parentDdCode = this.currentGroupForm.visibleConditionList[0].parentDdCode
                  showCondition.operator = this.currentGroupForm.visibleConditionList[0].operator
                  showCondition.parentValue = this.currentGroupForm.visibleConditionList[0].parentValue
                }
                if (type === 's') {
                  localStorage.setItem('isOnlySave',0)
                }else {
                  localStorage.setItem('isOnlySave',1)
                }
                this.$emit('addGroupLabel',{v:Object.assign(this.groupTagForm,showCondition),cb:()=>{
                  localStorage.setItem('groupCount',JSON.stringify(this.groupTagForm.groupCount))
                  this.$emit('initGroupTagForm')
                }})
              }
            })
          }
        }
      })
    },
    sectionChange(e, index){
       let v = this.currentGroupForm.visibleConditionList[0]
       this.onGetSection() 
    },
    sectionChangeClear(e,index){
      let v= this.currentGroupForm.visibleConditionList[0]
      v.sectionCode = ''
      v.parentDdCode = ''
      v.operator = ''
      v.parentValue = ''
      this.$emit('setCurrentGroupFormList',[])
      this.$emit('setCurrentGroupVisibleVariableList',[])
    },
    formChangeClear(){
      let v= this.currentGroupForm.visibleConditionList[0]
      v.parentDdCode = ''
      v.operator = ''
      v.parentValue = ''
      this.$emit('setCurrentGroupVisibleVariableList',[])
    },
    //条件显示 获取访视下的表单的变量
    variableChange(e, index){
      let v =  this.currentGroupForm.visibleConditionList[0].sectionList[index]
        let params = {
        registryEntityCode: this.$route.query.registryEntityCode,
        sectionCode: this.currentGroupForm.visibleConditionList[0].sectionCode,
        groupRepeat: 0
      }
      this.visibleLoading = true
      getVariable(params).then(r => {
        let d = r.data.data;
        this.$emit('setCurrentGroupVisibleConditionList',{d:d,code:this.ddCode})
        this.visibleLoading = false
      }).catch(e => {
        this.visibleLoading = false
        this.$emit('setCurrentGroupVisibleVariableList',[])
      })
      
    },
    variableChangeClear(){
      let v= this.currentGroupForm.visibleConditionList[0]
      v.operator = ''
      v.parentValue = ''
    },
    formChange(e, index){

      let v =  this.currentGroupForm.visibleConditionList[0].sectionList[index]
      let params = {
        registryEntityCode:this.$route.query.registryEntityCode,
        parentCode: this.currentGroupForm.visibleConditionList[0].parentSectionCode,
        sectionCode: v.selectionType ? this.$route.query.sectionCode : this.$route.query.sectionCode,
        sectionType: v.selectionType,
      };
      this.formLoading = true
      getSectionForm(params).then(r => {
        this.$emit('setCurrentGroupFormList',r.data.data)
        this.formLoading = false
      }).catch(e => {
        this.$emit('setCurrentGroupFormList',[])
        this.formLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.replate-wrap {
  display: flex;
  align-items: center;
  height: 28px;

  /deep/ .el-input {
    margin-left: 14px;
    width: 80px;
    margin-right: 14px;
  }

  span {
    font-size: 12px;
    color: #F56C6C;
  }

}

/deep/ .el-button {
  margin-right: 12px;
}
.visible-show {
  /deep/ .el-form-item__label {
    position: relative;
    &::after{
      content: '*';
      color: red;
      position: absolute;
      left: -6px;
      top: 0;
    }
  }
}
</style>
