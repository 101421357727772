import Vue from 'vue'
import request from './axios'
export function getCurrentItem(params) { //
    return request({
        url: '/api/tModelMappingSectionDd/getCurrentItem',
        method: 'GET',
        params,
    })
}
//获取列表
export function getFormList(params) { //
    return request({
        url: '/api/tModelMappingSectionDd/lookForDdOptionBySection',
        method: 'GET',
        params,
    })
}

//add项
export function addFormItem(data) { //
    return request({
        url: '/api/ddStructure/saveBean',
        method: 'POST',
        data,
    })
}

//add项关联
export function addFormItemContact(data) { //
    return request({
        url: '/api/tModelMappingSectionDd/saveBean',
        method: 'POST',
        data,
    })
}

//updata项
export function updateFormItem(data) { //
    return request({
        url: '/api/ddStructure/updateBean',
        method: 'POST',
        data,
    })
}

//updata项关联
export function updateFormItemContact(data) { //
    return request({
        url: '/api/tModelMappingSectionDd/updateBean',
        method: 'POST',
        data,
    })
}

//delete前 检查是否被引用，
export function ckDelRef(data) { //
    return request({
        url: '/api/tModelMappingSectionDd/ckDelRef',
        method: 'POST',
        data,
    })
}

//delete项
export function deleteFormItem(data) { //
    return request({
        url: '/api/tModelMappingSectionDd/deleteBean',
        method: 'POST',
        data,
    })
}

//sort项
export function sortFormItem(data) { //
    return request({
        url: '/api/tModelMappingSectionDd/saveBeans',
        method: 'POST',
        data,
    })
}


/*
条件显示
 */

//获取访视
export function getSection(params) { //
    return request({
        url: '/api/sectionStructure/getSection',
        method: 'GET',
        params,
    })
}

//获取表单
export function getSectionForm(params) { //
    return request({
        url: '/api/sectionStructure/getForm',
        method: 'POST',
        params,
    })
}

//获取变量
export function getVariable(params) { //
    return request({
        url: '/api/tModelMappingSectionDd/getVariable',
        method: 'POST',
        params,
    })
}

/*
组标签
 */

//获取
export function getGrouptag(params) { //
    return request({
        url: '/api/group/getAllList',
        method: 'GET',
        params,
    })
}

export function addGrouptag(data) { //
    return request({
        url: '/api/group/addBean',
        method: 'POST',
        data,
    })
}

// 获取选项集
export function getOptionSet(params) { //
  return request({
    url: '/api/ddStructure/getOptionSet',
    method: 'GET',
    params,
  })
}

// 删除指定选项集
export function deleteOptionSet(data) { //
  return request({
    url: '/api/ddStructure/deleteOptionSet',
    method: 'POST',
    data,
  })
}

// 添加选项集
export function saveOptionSet(data) { //
  return request({
    url: '/api/ddStructure/saveOptionSet',
    method: 'POST',
    data,
  })
}

// 添加选项集图片上传
export function fileUploadOptionImage(data) {
  return request({
    url: '/api/ddStructure/fileUpload',
    method: 'POST',
    data,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

// 新增组标签
export function addGroupChild(data) {
  return request({
    url: '/api/group/addGroupChild',
    method: 'POST',
    data
  })
}
// 查看组标签
export function selGroupChild(data) {
  return request({
    url: '/api/group/selGroupChild',
    method: 'POST',
    data
  })
}
// 删除组标签
export function delGroupChild(data) {
  return request({
    url: '/api/group/delGroupChild',
    method: 'POST',
    data
  })
}