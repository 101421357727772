<template>
    <div class="form_detail">
        <div class="common-header">
            <i class="el-icon-arrow-left visit-back" @click="()=>{this.$router.go(-1)}"></i>
            <p style="word-break: keep-all;width:50%;">{{researchName}}</p>

            <div class= 'common-h-btn-wrap'>
                <el-radio-group v-model="tabType" size = 'mini' @change = 'handleClick'>
                    <el-radio-button label="desigin">设计</el-radio-button>
                    <el-radio-button label="preview">预览</el-radio-button>
                </el-radio-group>
            </div>
            <div class="route-view">
                <router-view ></router-view>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapState} from 'vuex'
    export default {
        data() {
            return {
                tabType:'',
                currentPath:'',
                researchName:''
            };
        },
        created() {
            if (this.tabPosition == 'desigin') {
                if (JSON.parse(localStorage.getItem('fullPath'))) {
                    this.currentPath = localStorage.getItem('fullPath');
                } else {
                    localStorage.setItem('fullPath',JSON.stringify(this.$route.fullPath))
                }
            }
        },

        methods: {
            ... mapMutations(['setTabPosition']),
            handleClick(e){
                this.setTabPosition(e)
                if (e === 'desigin') {
                    this.$router.replace(JSON.parse(localStorage.getItem('fullPath')))
                }else{
                    this.$router.replace({name:'formPreview',query:{... this.$route.query}})
                }
            }
        },
        computed: {
            ... mapState(['tabPosition']),
        },
        watch: {
            '$route':{
                immediate:true,
                handler:function (n) {
                    console.log(n);
                    this.researchName = n.query.name
                    if (n.meta.isPreview) {
                        this.setTabPosition('preview')
                    }else {
                        this.setTabPosition('desigin')
                    }

                }
            },
            'tabPosition':{
                immediate:true,
                handler:function (n) {
                    this.tabType = this.tabPosition
                }
            }
        }
    };
</script>

<style lang='scss' scoped>

    .form_detail {
        margin-top:48px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .common-header {
            padding-top:0;
            background-color: #fff;
            .common-h-btn-wrap {
                top:48px;
                position:absolute;
                left:50%;
                transform: translateX(-50%);
            }
        }
    }
    .route-view {
        width: 100%;
        height: 100%;
        margin-top: 100px;
    }

</style>