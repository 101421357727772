<template>
    <el-card class="box-card" :body-style="{ height: 'calc(100% - 117px)',overflowY: 'auto',padding: '15px' }">
        <div slot="header" class="clearfix">
            <el-input v-model="search" size="smmall" @input='selectContrl' placeholder="请输入关键字查找组件"></el-input>
        </div>
        <el-scrollbar style="height:100%">
            <draggable
                    class="list-group"
                    v-model="controlList"
                    :clone="cloneElement"
                    :group="{ name: 'people', pull: 'clone', put: false }"
                    @change="log">
                <transition-group type="transition" :name="'flip-list'">
                    <div class="list-group-item item clearfix"
                         v-for="element in controlList" :key="element.name">
                         <template v-if="sectionType=='Single'||element.type!='SUF'">
                             <i class="iconfont fl" :class="element.icon"></i>
                            <span class="fl">{{element.subName}}</span>
                            <i class="iconfont icon-tuodong fr"></i>
                        </template>
                    </div>
                </transition-group>
            </draggable>
        </el-scrollbar>
    </el-card>
</template>


<script>
    import draggable from "vuedraggable";
    let baseControlArr = [
        {
            name: '单行文本',
            subName: '单行文本',
            type: 'TB',
            id: 1,
            fixed: false,
            icon: 'icon-danhangwenben'
        },
        {
            name: '多行文本',
            subName: '多行文本',
            type: 'TA',
            fixed: false,
            id: 2,
            icon: 'icon-duohang'
        }, {
            name: '单选题',
            subName: '单选题',
            type: 'RB',
            fixed: false,
            id: 3,
            icon: 'icon-iconfontoptionbutton'
        },
        {
            name: '下拉单选',
            subName: '下拉单选',
            type: 'SEL_SIG',
            id: 4,
            fixed: false,
            icon: 'icon-xialadanxuan'
        }, {
            name: '数字题',
            subName: '数字题',
            type: 'NTX',
            fixed: false,
            id: 5,
            icon: 'icon-shuzi'
        }, {
            name: '多选题',
            subName: '多选题',
            type: 'CB',
            fixed: false,
            id: 6,
            icon: 'icon-duoxuanti'
        }, {
            name: '时间选择器',
            subName: '时间选择器',
            type: 'TP',
            fixed: false,
            id: 7,
            icon: 'icon-shijianxuanzeqi'
        }, {
            name: '日期选择器',
            subName: '日期选择器',
            type: 'DP',
            fixed: false,
            id: 8,
            icon: 'icon-shumogongjuicon-'
        }, {
            name: '日期时间选择器',
            subName: '日期时间选择器',
            type: 'DTP',
            fixed: false,
            id: 9,
            icon: 'icon-riqishijian'
        }, {
            name: '地址选择器',
            subName: '地址选择器',
            type: 'ADSEL',
            fixed: false,
            id: 10,
            icon: 'icon-dizhixuanze'
        },
        // {
        //     name:'position',
        //     subName:'定位',
        //     type:'AD',
        //     fixed: false,
        //     id:11,
        // },
        // {
        //     name: '文件上传',
        //     subName: '文件上传',
        //     type: 'SUF',
        //     fixed: false,
        //     id: 12,
        //     icon: 'icon-shangchuan'
        // },
        {
            name: '文本标题',
            subName: '文本标题',
            type: 'LB',
            fixed: false,
            id: 13,
            icon: 'icon-wenbenbiaoti'
        }
    ]
    export default {
        components: {
            draggable
        },
        props: ['formData', "sectionType"],
        data() {
            return {
                search: '',
                controlList: baseControlArr,
                idGlobal: 1,
            }
        },
        methods: {
            selectContrl() {
                let search = this.search
                if (search) {
                    this.controlList = this.controlList.filter(item => {
                        return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.subName.toLowerCase().indexOf(search.toLowerCase()) > -1
                    })
                } else {
                    this.controlList = baseControlArr
                }
            },
            cloneElement(clone) {
                return {
                    pkId: this.idGlobal++,
                    name: clone.name,
                    ddName: clone.name,
                    unitOfMeasure: '',
                    defaultValue: '',
                    displayOrder: this.idGlobal,
                    visible: 1,
                    isCalculate: 0,// 0输入,1 计算
                    dataType: '0',//是否只读 1是,0否
                    isCondition: 0,
                    isImExport: 0,
                    rule: 'all',
                    groupCode: this.formData.defaultGroupCode,
                    controlType: clone.type,
                    value: clone.type === 'CB' ? [] : '',
                    desensitization: 'bypassed', //脱敏设置
                    scopeValid: 0,//范围核查,1是,0否
                    allowDecimal: 0, //允许小数,1是 0 否
                    decimalDigits: 2,
                    isLogicCk: 0,
                    scopeRange_up: 0,
                    scopeRange_down: 0,
                    scopeRange: '',//数值范围
                    isCalculateEdit: 0,
                    calculateExp: '',
                    itemOptionList: [
                        {
                            displayName: '',
                            value: '',
                            registryEntityCode: this.formData.registryEntityCode,
                            sectionCode: this.formData.sectionCode,
                        }
                    ],
                    calculatorMain: {
                        pkId: '',
                        registryEntityCode: this.$route.query.registryEntityCode,
                        sectionCode: this.$route.query.sectionCode,
                        code: '',
                        ddCode: '',
                        desc: '',
                        calculate: '',
                        calculateDesc: '',
                        ruleStructure: '',
                        isEdit: 0,//是否核查 0不核查, 1 核查
                        status: '',
                        type: 1,//1 数字 2 日期
                        dateUnit: 1,
                    },
                    visibleConditionList: [
                        {
                            pkId: '',
                            registryEntityCode: this.formData.registryEntityCode,//研究
                            parentSectionCode: '',//访视
                            sectionCode: '',//表单
                            parentDdCode: '',//作为条件的变量
                            parentValue: '',// 条件值
                            operator: '',//操作符
                            ddCode: '',//标识在这个字段上做了条件显示
                            type: '1',// 目前只有 1 。'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
                            sectionList: [],//选择访视
                            formList: [],//选择表单
                            variableList: [],//选择变量
                            controlType: '',
                        }
                    ],
                };
            },
            log() {

            }
        },
    }
</script>
<style lang='scss' scoped>
    .box-card {
        height: 100%;
        .input {
            border-bottom: 1px solid #ebeef5;
        }
        .item {
            width: 100%;
            margin: 0 auto;
            color: #606266;
            cursor: move;
            border: 1px solid #ebeef5;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            text-overflow: ellipsis;
            border-radius: 3px;
            padding: 6px 8px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 1.5em;
            .comp-item-title {
                font-weight: 700;
                color: #222;
            }
            &.input {
                width: 180px;
            }
        }
        .flip-list-move {
            transition: transform 0.8s;
        }

        .no-move {
            transition: transform 0s;
        }

        .ghost {
            opacity: 0.5;
            background: #c8ebfb;
            color: red;
        }

        .list-group {
            // min-height: 20px;
            height: 100%;
        }

        .list-group-item {
            border: 1px solid #eee;
            box-sizing: border-box;
            padding: 10px 10px;
            cursor: move;
            span {
                margin-left: 10px;
                vertical-align: top;
            }
        }

    }
</style>