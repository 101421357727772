<template>
    <div class="form_detail" :visible.sync="dialogVisible">
        <div class="form_content">
            <div class="content_left">
                <FormEditItem :class="{'ndisabled':canEditForm}" :formData="formData" :sectionType="sectionType"/>
            </div>
            <div class="content_right">
                <el-row style="height:100%" :gutter="30">
                    <el-col :span="12" style="height:100%;" ref="colWidth">
                        <el-scrollbar style="height:100%">
                            <el-form label-position="top" v-model="sureFormList" size="mini" class="formScroll"
                                     >
                                <draggable filter=".undraggable" class="dragArea list-group" :list="sureFormList" group="people"
                                           @change="log">
                                    <div title="拖拽控件以排序" class="list-group-item" v-for="(item,index) in sureFormList"
                                         :key="item.pkId" @click="SeeItemConfig(item,index)"
                                         :class="{'item_focu':index==current, 'undraggable':canEditForm}" >
                                        <!-- 单文本 -->
                                        <el-form-item v-if="item.controlType==='TB'" :label="item.ddName">
                                            <el-input type="input">
                                                <template slot="append" v-if="item.unitOfMeasure">{{item.unitOfMeasure}}
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                        <!-- 单选 -->
                                        <el-form-item v-if="item.controlType==='RB'" :label="item.ddName">
                                            <template>
                                                <el-radio-group>
                                                    <el-radio v-for="(listItem,index) in item.itemOptionList"
                                                              :key="index" :label="listItem.displayName">
                                                        {{listItem.displayName}}
                                                    </el-radio>
                                                </el-radio-group>
                                            </template>
                                        </el-form-item>
                                        <!-- 多选 -->
                                        <el-form-item v-if="item.controlType==='CB'" :label="item.ddName">
                                            <template>
                                                <el-checkbox-group @change="CBChange" v-if="item.itemOptionList.length"
                                                                   v-model="item.value">
                                                    <el-checkbox v-for="(listItem,index) in item.itemOptionList"
                                                                 :key="index" :label="listItem.value">
                                                        {{listItem.displayName}}
                                                    </el-checkbox>
                                                </el-checkbox-group>
                                            </template>
                                        </el-form-item>
                                        <!-- 下拉 -->
                                        <el-form-item v-if="item.controlType==='SEL_SIG'" :label="item.ddName">
                                            <template>
                                                <el-select filterable collapse-tags v-model="item.numConfig"
                                                           placeholder="请选择">
                                                    <el-option v-for="listItem in item.itemOptionList"
                                                               :key="listItem.displayName" :label="listItem.displayName"
                                                               :value="listItem.value"></el-option>
                                                </el-select>
                                            </template>
                                        </el-form-item>
                                        <!-- 日期选择 -->
                                        <el-form-item v-if="item.controlType==='DP'" :label="item.ddName">
                                            <template>
                                                <el-date-picker value-format="yyyy-MM-dd" v-model="item.value"
                                                                type="date" placeholder="选择日期"></el-date-picker>
                                            </template>
                                        </el-form-item>
                                        <!-- 时间选择 -->
                                        <el-form-item v-if="item.controlType==='TP'" :label="item.ddName">
                                            <template>
                                              <el-time-picker placeholder="选择时间"></el-time-picker>
                                            </template>
                                        </el-form-item>
                                        <!-- 时间日期选择 -->
                                        <el-form-item v-if="item.controlType==='DTP'" :label="item.ddName">
                                            <template>
                                                <el-date-picker format="yyyy-MM-dd HH:mm" v-model="item.value"
                                                                type="datetime" placeholder="选择日期时间"></el-date-picker>
                                            </template>
                                        </el-form-item>
                                        <!-- 数字 -->
                                        <el-form-item v-if="item.controlType==='NTX'" :label="item.ddName">
                                            <template>
                                                <el-input type="number">
                                                    <template slot="append" v-if="item.unitOfMeasure">
                                                        {{item.unitOfMeasure}}
                                                    </template>
                                                </el-input>
                                            </template>
                                        </el-form-item>
                                        <!-- 多行文本 -->
                                        <el-form-item v-if="item.controlType==='TA'" :label="item.ddName">
                                            <template>
                                                <el-input type="textarea" rows="1"></el-input>
                                            </template>
                                        </el-form-item>
                                        <!-- LABEL -->
                                        <el-form-item v-if="item.controlType==='LABEL'" :label="item.ddName">
                                            <template>
                                                <el-input type="textarea" v-model="item.value"
                                                          :style="{'visibility':'hidden'}"></el-input>
                                            </template>
                                        </el-form-item>
                                        <!-- 文件上传 -->
                                        <el-form-item v-if="item.controlType==='SUF'" :label="item.ddName">
                                            <el-upload action="" :show-file-list="false" :auto-upload="false">
                                                <el-button slot="trigger" size="mini" type="primary">文件选取</el-button>
                                            </el-upload>
                                        </el-form-item>
                                        <!-- 地址选择 -->
                                        <el-form-item v-if="item.controlType==='ADSEL'" :label="item.ddName">
                                            <el-cascader size="mini" style="width:100%" :options="options"
                                                         v-model="item.value" @change="ADSELChange"></el-cascader>
                                        </el-form-item>
                                        <!-- 文本标题 -->
                                        <el-form-item v-if="item.controlType==='LB'"
                                                      :label="item.ddName"></el-form-item>

                                        <span class="top_right_two">
                    <el-tag type="success" v-if="item.ddCode">已保存</el-tag>
                    <el-tag type="info" v-else>未保存</el-tag>
                  </span>
                                        <el-popconfirm title="确定删除该项吗？" @confirm="ckDelRef(item,index)" :class="{'ndisabled':canEditForm}" >
                                            <i slot="reference" class="el-icon-delete top_right" :class="{'ndisabled':canEditForm}" ></i>
                                        </el-popconfirm>
                                    </div>
                                    <div v-if="sureFormList.length == 0" class="noDate list-group-item"> 从左侧拖拽来添加表单项
                                    </div>
                                </draggable>
                            </el-form>
                        </el-scrollbar>
                    </el-col>
                    <el-col :span="12" class="" style="height:100%;padding:0;"
                            v-show="currentFormItem.pkId || currentFormItem.ddCode ">
                        <el-scrollbar style="height:100%" v-if="sureFormList.length">
                            <div class="config_body">
                                <el-form :model="currentFormItem" size="small" class="formScroll" label-width="100px"
                                         :rules="rules" ref="currentFormItem">

                                    <el-tabs type="border-card" v-model="activeTab" :stretch="Boolean(true)" v-loading="currentLoading">
                                        <el-tab-pane label="变量" name="variable" :class="{'ndisabled':canEditForm}">
                                            <el-form-item label="问题" prop="ddName">
                                                <el-input v-model="currentFormItem.ddName"></el-input>
                                            </el-form-item>

                                            <el-form-item v-show="showDesignItem(currentFormItem).textLabel" label="级别"
                                                          prop="numConfig"
                                                          :rules="[{required: currentFormItem.controlType == 'LB', message: '请选择标题级别', trigger: 'change,blur'},]">
                                                <template>
                                                    <el-radio-group v-model="currentFormItem.numConfig">
                                                        <el-radio value="1" label="18px">一级标题</el-radio>
                                                        <el-radio value="2" label="16px">二级标题</el-radio>
                                                        <el-radio value="3" label="14px">三级标题</el-radio>
                                                    </el-radio-group>
                                                </template>
                                            </el-form-item>
                                            <!--
                                                                   标签所对应的英文名
                                                                   问题: 你的身高是多少  变量名: height
                                                                      -->
                    <el-form-item label="变量名" v-if="showDesignItem(currentFormItem).ddDisplayNameEn" prop="ddDisplayNameEn">
                      <el-input v-model="currentFormItem.ddDisplayNameEn"></el-input>
                    </el-form-item>
                    <el-form-item label="单位" prop="unitOfMeasure" v-if="showDesignItem(currentFormItem).unitOfMeasure">
                      <el-input v-model="currentFormItem.unitOfMeasure"></el-input>
                    </el-form-item>
                    <!--对这个输入框之类的组件进行说明-->
                    <el-form-item v-if="showDesignItem(currentFormItem).ddDesc" prop="ddDesc" label="描述">
                      <el-input v-model="currentFormItem.ddDesc"></el-input>
                    </el-form-item>
                    <el-form-item label="组标签" v-if="showDesignItem(currentFormItem).groupTag">
                      <div class="group-wrap">
                        <el-popover class="pop-wrap-z" placement="bottom" width="355" v-model="isShowGroupSelect" trigger="click">
                        <div class="item-wrap-g" v-loading="deleteLoading">
                          <div v-for="(item,index) in groupTagList" :key="index" :class="{ 'select-item-wrap-active': currentFormItem&&currentFormItem.groupName ==item.groupName}" class="select-item-wrap" style="display:flex;justify-content:space-between;padding:10px 6px;">
                            <p style="flex:1; cursor: pointer;" class="title-wrap" @click.stop="onSelectGroupItem(item)">
                              <i class="icon-box icon-box-repeat" v-if="item.isRepeat == 1"></i>
                              <i class="icon-box" v-else></i>
                              {{item.groupName}}
                              </p>
                            <p v-if="index != 0">
                              <i style="margin-right:12px;" @click="onEditGroup({v:item,type:1})" type="primary" class="el-icon-edit" ></i>
                              <el-popconfirm @confirm='onEditGroup({v:item,type:0})' confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red" title="确定要删除此组标签吗？">
                                <i slot="reference" class="el-icon-delete" ></i>
                              </el-popconfirm>
                            </p>
                          </div>
                        </div>
                        <el-input :readonly="true" placeholder="请选择组标签" v-model="currentFormItem.groupName" slot="reference">
                          </el-input>
                      </el-popover>
                      <p class="add-g-btn"><el-button @click="handleIconClick"  circle icon="el-icon-plus"></el-button></p>
                      </div>
                    </el-form-item>

                    <!--
                                                                        重复表单约束问题
                                                                           重复表单 可以选择 非重复表单 以及当前表单的值
                                                                           非重复表单只能选择非重复表单中的值
                                                                    -->
                                                                    <!-- currentFormItem.showVisible&& -->
                    <el-form-item label="是否显示" v-if="currentFormItem.groupName==='未定义组'&&currentFormItem.showVisible&&showDesignItem(currentFormItem).visible">
                      <template>
                        <el-radio-group v-model="currentFormItem.visible">
                          <el-radio value="1" :label="1">是</el-radio>
                          <el-radio value="0" :label="0">否</el-radio>
                        </el-radio-group>
                      </template>
                    </el-form-item>

                    <el-form-item label="设置显示条件" v-if="currentFormItem.groupName==='未定义组'&&currentFormItem.showVisible&&showDesignItem(currentFormItem).visibleSetting">
                      <el-form :model="currentFormItem" ref="currentConditionForms" size="small">
                        <div v-for="(domain, index) in currentFormItem.visibleConditionList" :key="domain.key">
                          <el-form-item :prop="'visibleConditionList.' + index + '.parentSectionCode'" :rules="visibleConditionRules.parentSectionCode">
                            <el-select style="width: 100%" v-model="domain.parentSectionCode" filterable collapse-tags placeholder="选择访视" @focus= "getSectionList(domain, index)" @change= "sectionChangeAndGetForm($event,index)">
                              <el-option v-for="ee in domain.sectionList" :key="ee.code" :label="ee.name" :value="ee.code"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="" :prop="'visibleConditionList.'+index+'.sectionCode'" :rules="visibleConditionRules.sectionCode">
                            <el-select style="width: 100%" v-model="domain.sectionCode" filterable @change="formChangeAndGetVariable($event,index)" @focus= "getVisibleFormList(index)" collapse-tags placeholder="选择表单">
                              <el-option v-for="ee in domain.formList" :key="ee.code" :label="ee.name" :value="ee.code">
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item label="" :prop="'visibleConditionList.'+index+'.parentDdCode'" :rules="visibleConditionRules.parentDdCode">
                            <el-select style="width: 100%" v-model="domain.parentDdCode" @change="variableChange($event,index)" @focus= "getVisibleVariableList(index)" placeholder="选择变量">
                              <el-option v-for="ee in domain.variableList" :key="ee.ddCode" :label="ee.ddName" :value="ee.ddCode"></el-option>
                            </el-select>
                          </el-form-item>

                          <el-form-item label="" :prop="'visibleConditionList.'+index+'.operator'" :rules="visibleConditionRules.operator">
                            <el-select style="width: 100%" @change="visibleOperatorChange($event,index)" v-model="domain.operator" collapse-tags placeholder="选择操作">
                              <el-option v-for="ee in selector.operatorList" :key="ee.value" :label="ee.label" :value="ee.value"></el-option>
                            </el-select>

                          </el-form-item>
                          <el-form-item v-if="domain.operator=='{}'||domain.operator=='{/}'" label="" :prop="'visibleConditionList.'+index+'.parentValue'" :rules="visibleConditionRules.parentValue">
                            <el-input type="textarea" rows="2" placeholder="多个值请使用英文逗号(,)分隔" v-model="domain.parentValue"></el-input>
                          </el-form-item>

                          <el-form-item v-else label="" :prop="'visibleConditionList.'+index+'.parentValue'" :rules="visibleConditionRules.parentValue">
                            <el-input type="input" placeholder="输入值" v-model="domain.parentValue"></el-input>
                          </el-form-item>

                        </div>
                      </el-form>
                    </el-form-item>

                    <!--<el-form-item v-if="currentFormItem.controlType!='LB'" label="填写指南"-->
                    <el-form-item v-if="showDesignItem(currentFormItem).ddInputGuide" label="填写指南" prop="ddInputGuide">
                      <el-input type="textarea" rows="3" v-model="currentFormItem.ddInputGuide"></el-input>
                    </el-form-item>
                    <el-form-item label="SDV" v-if="currentFormItem.isShowSDV">
                      <el-radio-group v-model="currentFormItem.sdv">
                        <el-radio :label="'1'">是</el-radio>
                        <el-radio :label="'0'">否</el-radio>
                      </el-radio-group>
                    </el-form-item>

                  </el-tab-pane>
                  <el-tab-pane label="值" v-if="showDesignItem(currentFormItem).tabPaneValue " :class="{'ndisabled':canEditForm}" name="value">
                    <el-row>
                      <el-col :span="12" style="min-width:245px;">
                        <el-form-item label="允许小数" v-show="showDesignItem(currentFormItem).allowDecimal">
                          <div>
                            <el-radio-group v-model="currentFormItem.allowDecimal">
                              <el-radio :label="1">是</el-radio>
                              <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                          </div>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" v-if="showDesignItem(currentFormItem).decimalDigits">
                        <el-form-item label="小数位数" prop="decimalDigits">
                          <el-input type="number" size="mini" style="width:60px;" v-model="currentFormItem.decimalDigits"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :span="10" style="min-width:245px;">
                        <el-form-item label="范围核查" v-show="showDesignItem(currentFormItem).scopeValid">
                          <template>
                            <el-radio-group v-model="currentFormItem.scopeValid">
                              <el-radio :label="1">是</el-radio>
                              <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                          </template>
                        </el-form-item>
                      </el-col>
                      <el-col :span="10" v-if="showDesignItem(currentFormItem).scopeRange" style="display:flex;">
                        <div style="display: inline-block">
                          <el-form-item prop="scopeRange_down" label="核查范围">
                            <el-input type="number" size="mini" style="width:8em" v-model="currentFormItem.scopeRange_down"></el-input>
                          </el-form-item>
                        </div>
                        <div style="display: inline-block">
                          <el-form-item prop="scopeRange_up" label-width="20px" style="width:8em" label="-">
                            <el-input type="number" size="mini" v-model="currentFormItem.scopeRange_up"></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>

                    <el-form-item v-if="showDesignItem(currentFormItem).selectorSet" label="选项集" prop="optionNames" :rules="[{ required:true,message:'选项集必须选择',trigger: 'change'}]">
                      <!-- <el-input v-model="currentFormItem.optionNames"
                                                                                      @click.native="openOptionsList"></el-input> -->
                      <el-popover placement="bottom" width="420">
                        <div class="input-top">
                          <el-input style="width:75%" v-model="optionsForm.search">
                            <i class="el-icon-search el-input__icon" slot="suffix" @click="getOptionList('1')">
                            </i>
                          </el-input>
                          <el-button type="primary" style="margin-left:10px" @click="openOptionsList">新增选项集
                          </el-button>
                        </div>
                        <el-scrollbar style="height:400px">
                          <el-table :data="optionListData" :show-header="false" style="width: 100%">
                            <el-table-column min-width="85%">
                              <template slot-scope="scope">
                                <span @click="selectOption(scope.$index, scope.row)" style="margin-left: 10px;cursor: pointer;">{{ scope.row.optionSetName }} {{ scope.row.optionValue }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column min-width="15%">
                              <template slot-scope="scope">
                                <el-button icon="el-icon-delete" circle @click="deleteOptionList(scope.$index, scope.row)"></el-button>
                              </template>
                            </el-table-column>
                          </el-table>
                        </el-scrollbar>
                        <el-input :readonly="true" v-model="currentFormItem.optionNames" slot="reference">
                        </el-input>
                      </el-popover>
                    </el-form-item>

                    <el-form-item label="是否必填">
                      <template>
                        <el-radio-group v-model="currentFormItem.isImExport" :disabled="currentFormItem.controlType=='LB'
                                                                ||currentFormItem.visible==0">
                          <el-radio :label="1">是</el-radio>
                          <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                      </template>
                    </el-form-item>

                    <el-form-item label="是否只读">
                      <template>
                        <el-radio-group v-model="currentFormItem.dataType" :disabled="currentFormItem.controlType=='LB'
                                                                ||currentFormItem.isCalculate==1
                                                                ||isEpro==1">
                          <el-radio v-for="(e ,i ) in selector.yesNoStr" :key="i" :value="e.value" :label="e.value">
                            {{e.text}}
                          </el-radio>

                        </el-radio-group>
                      </template>
                    </el-form-item>
                    <el-form-item label="数据来源" v-if="showDesignItem(currentFormItem).dataSource">

                      <template>
                        <el-select v-model="currentFormItem.isCalculate" placeholder="请选择" @change="datasourceChange" size="mini">
                          <el-option v-if="calculateItems.indexOf(currentFormItem.controlType) >= 0" v-for="ee in selector.calculateDataSource" :key="ee.label" :label="ee.label" :value="ee.value"></el-option>

                          <el-option v-if="calculateItems.indexOf(currentFormItem.controlType) < 0" v-for="ee in selector.commonDataSource" :key="ee.label" :label="ee.label" :value="ee.value"></el-option>

                        </el-select>
                        <i @click="onOpenCalculator" v-if="currentFormItem.isCalculate==1" class="iconfont icon-jisuan calculate-btn">
                        </i>
                      </template>
                    </el-form-item>
                    <div v-if="currentFormItem.isCalculate==2">
                      <div v-if="sectionType=='Single'">
                        <SingleDefaultForm 
                        :defaultSourceForm = 'defaultSourceForm' 
                        :defaultName = 'defaultName' 
                        :getSpirceList = 'getSpirceList' 
                        :currentItemNameList = 'currentItemNameList'></SingleDefaultForm>
                        <!--
                        <el-form-item label="默认值" prop="CBValue" v-if="currentFormItem.controlType == 'CB'">
                          <el-input v-model="CBValue"></el-input>
                        </el-form-item>
                        <el-form-item v-if="currentFormItem.controlType != 'LB'
                                                        &&currentFormItem.controlType != 'ADSEL'" label="默认值" prop="value">
                          <el-input v-model="currentFormItem.value"></el-input>
                        </el-form-item>
                        -->
                      </div>
                      <div v-if="sectionType=='Multiple'">
                        <!-- v-if="$route.query.repeatNum==0"-->
                        <div v-if="$route.query.repeatNum==0" style="padding-left: 3em">
                          当前表单已经设置为不支持默认值!
                        </div>
                        <!--v-if="$route.query.repeatNum>0"-->
                        <el-collapse v-model="activeNames" v-if="$route.query.repeatNum>0">
                          <el-collapse-item name="1">
                            <span slot="title" style="line-height: 28px;padding-left: 3em" class="el-form-item__label">
                              默认值
                            </span>
                              <SingleDefaultForm :defaultSourceForm = 'defaultSourceForm' :defaultName = 'defaultName' :getSpirceList = 'getSpirceList' :currentItemNameList = 'currentItemNameList'></SingleDefaultForm>

                            <!-- <FormEditMultiDefaultValue :ntxRule = 'ntxRule' type="TB" :numConfig="currentFormItem.numConfig" :repeatNum="$route.query.repeatNum" :defaultValue="currentFormItem.value" :controlType="currentFormItem.controlType" ref="multiDefaultValue" /> -->
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </div>
                    <!-- v-model="currentFormItem.calculateMain.desc"  -->
                    <el-form-item label="计算规则" prop="calculateMain.desc" :rules="[ {required: currentFormItem.isCalculate===1, message: `计算规则不能为空`, trigger: 'change,blur'}]" v-if="showDesignItem(currentFormItem).calculateRule && currentFormItem.isCalculate ===1">
                      <el-input v-model="currentFormItem.calculateMain.desc" v-if="currentFormItem.calculateMain" type="textarea" :readonly="Boolean(true)" rows="3"></el-input>
                    </el-form-item>
                    <!--暂未开发-->
                    <el-form-item label="逻辑核查" v-if="1==2">
                      <template>
                        <el-radio-group v-model="currentFormItem.isLogicCk" :disabled="currentFormItem.controlType=='LB'||currentFormItem.visible==0">
                          <el-radio :label="1">是</el-radio>
                          <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                      </template>
                    </el-form-item>
                    <!--暂未开发-->

                    <el-form-item label="核查规则" v-if="1==2||currentFormItem.isLogicCk==1">
                      <el-input type="textarea" v-model="currentFormItem.calculateMain.desc" :readonly="Boolean(true)" rows="3"></el-input>
                    </el-form-item>
                  </el-tab-pane>
                </el-tabs>

                <div class="footer_div">
                  <span @click="handleSaveFormItem(!currentFormItem.ddCode?'save':'edit')" class="span" :class="{'disabled':canEditForm}">
                    <el-button :loading="btnLoading" type="primary" :class="{'disabled':canEditForm}">{{!currentFormItem.ddCode?'保存':'确定'}} </el-button>
                  </span>
                </div>
                <!-- <div class="footer_div" v-else>
                  <span @click="handleSaveFormItem('edit')" class="span" :class="{'disabled':canEditForm}">
                    <el-button :loading="btnLoading" type="primary" :class="{'disabled':canEditForm}"></el-button>
                  </span>
                </div> -->
              </el-form>
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>
    </div>
  </div>

  <div v-if="openCalculator">
    <el-dialog title="设置计算规则" :visible.sync="openCalculator" width="40%" top="10vh" :destroy-on-close='true'>
      <logic-calculator ref="logicCalculator"
        :dateUnit="parseInt(currentFormItem.calculateMain.dateUnit)||1"
                                  :type="parseInt(currentFormItem.calculateMain.type)||1" 
                                  :researchCode="registryEntityCode" 
                                  :parentCode="parentCode" 
                                  :expArr = 'expArr'
                                  :sectionCode="sectionCode" 
                                  :itemControlTypeParam="currentFormItem.controlType" 
                                  :ddCode="currentFormItem.ddCode" calcuatorType="cal" 
                                  :currentItem="currentFormItem" 
                                  :sectionType="sectionType" 
                                  :exp="currentFormItem.calculateMain.calculate" 
                                  :exp-arr="currentFormItem.calculateMain.calculateDesc" 
                                  v-on:saveCalculateExp="saveCalculateExp" 
                                  v-on:cancelCalculateExp="cancelCalculateExp" />
      <!--<logic-calculator ref="logicCk"/>-->
    </el-dialog>
  </div>
  <!-- 选项集  start-->
  <el-drawer :visible.sync="dialogVisibleList" :with-header="false" size="35%" @open="popoverShow">
    <el-col :span="24">
      <b class="addTitle">新增选项集</b>
      <el-form label-position="top" size="mini" :model="optionsForm" ref="optionsForm">
        <el-form-item label="选项集名称" prop="optionSetName" label-width="100px" :rules="[{ required:true,message:'选项集名称',trigger: 'blur'}]">
          <el-input v-model="optionsForm.optionSetName"></el-input>
        </el-form-item>
        <template v-for="(item, index) in optionsForm.itemOptionFormList">
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="选项" :prop="'itemOptionFormList.' + index + '.selectName'" :rules="[{ required:true,message:'选项',trigger: 'blur'}]">
                <el-input v-model="item.selectName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="选项编码" :prop="'itemOptionFormList.' + index + '.selectCode'" :rules="[{ required:true,message:'选项编码必须是数字',trigger: 'change',type:'number'}]">
                <el-input v-model.number="item.selectCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="操作" label-width="150px">
                <el-col :span="14">
                  <el-upload class="upload-demo" :data="{optionIndex : index}" action="" :show-file-list="false" :http-request="upload">
                    <el-button type="primary" size="mini">{{item.selectImage}}</el-button>
                  </el-upload>
                </el-col>
                <el-col :span="10">
                  <i @click="optionsItem" class="el-icon-circle-plus-outline" style="margin-left:10px;color:#787878;"></i>
                  <i @click="optionsDeleteItem(index)" class="el-icon-delete" style="margin-left:10px;color:#787878;"></i>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <el-form-item>
          <el-button @click="submitSaveOptionSet('optionsForm')" :loading="btnLoading" type="primary" size="mini">保存并应用
          </el-button>
        </el-form-item>
      </el-form>
      <el-button style="width: 100%;" slot="reference" size="mini" type="primary">新增选项集
      </el-button>
    </el-col>
  </el-drawer>
  <!-- 新增组标签-->
  <el-drawer :visible.sync="dialogVisibleGroup" :with-header="false" size="35%" @open="popoverShowGroup">
    <el-col :span="24">
      <b class="addTitle">新增组标签</b>
      <RepeatGroupFormDrawer v-loading="editGroupLoading" @setCurrentGroupVisibleVariableList = 'setCurrentGroupVisibleVariableList' @setCurrentGroupVisibleConditionList = 'setCurrentGroupVisibleConditionList' @setCurrentGroupFormList = 'setCurrentGroupFormList' @setCurrentGroupForm = 'setCurrentGroupForm' :currentGroupForm = 'currentGroupForm' @setGroupTagFormReplaceNum = 'setGroupTagFormReplaceNum' @initGroupTagForm = 'initGroupTagForm' @initSureFormList = 'initSureFormList' :groupTagForm = 'groupTagForm' :gbtnLoading = 'gbtnLoading' :currentFormItem='currentFormItem' :selector='selector' @addGroupLabel = 'addGroupLabel' :isRepeat = 'currentFormItem.isRepeat' :ddCode = 'currentFormItem.ddCode'></RepeatGroupFormDrawer>
    </el-col>
  </el-drawer>
</div>
</template>

<script>
import {
  getCurrentItem,
  getFormList,
  ckDelRef,
  addFormItem,
  deleteFormItem,
  updateFormItem,
  sortFormItem,
  getSection,
  getSectionForm,
  getVariable,
  getGrouptag,
  addGrouptag,
  addGroupChild,
  getOptionSet,
  deleteOptionSet,
  saveOptionSet,
  selGroupChild,
  delGroupChild,
  fileUploadOptionImage
} from "@/api/formEdit";
import {
  regionDataPlus,
  CodeToText,
  TextToCode
} from 'element-china-area-data';
import draggable from "vuedraggable";
import globalConst from '../utils/globalConst'
import FormEditItem from './../components/FormEditItem.vue'
import LogicCalculator from './../components/LogicCalculator.vue'
import FormItemLabel from './../components/FormItemLabel.vue'
import RepeatGroupFormDrawer from './../components/RepeatGroupFormDrawer'
import SingleDefaultForm from './../components/formEdit/SingleDefaultForm'
import {
  SelectorDefine
} from '../utils/SelectorDefine'
import FormEditMultiDefaultValue from '../components/formEdit/FormEditMultiDefaultValue'
import {
  isArray
} from "@/utils/index";
import {
  mapState
} from 'vuex';

export default {
  components: {
    FormEditItem,
    draggable,
    LogicCalculator,
    FormItemLabel,
    FormEditMultiDefaultValue,
    RepeatGroupFormDrawer,
    SingleDefaultForm
  },
  data() {
    return {
      isSaved:false,
      editGroupLoading:false,
      isChangeOrder:false,
      currentLoading:true,
      deleteLoading:false,
      groupTagForm: {
        groupName: '未定义组',
        isRepeat: false,
        groupCount: 1,
        show:1,
        allow:1
      },
      groupCodeGet:{
        groupCode:'',
        pkId:''
      },
      defaultSourceForm:{
        sureFormList:[]
      },
      currentItemNameList:{
        defaultName:'',
        itemOptionList:[]
      },
      defaultName:'',
      gbtnLoading:false,
      isShowGroupSelect: false,
      dialogVisibleGroup: false,
      showGuide: false,
      calculateItems: ['NTX', 'DP', 'TP', 'DTP'], //可计算的四种类型
      openCalculator: false,
      selector: {},
      current: -1,
      btnLoading: false,
      removedItemCodes: [],
      hasRemoved: false,
      activeTab: 'variable',
      visibleCondition: {
        pkId: '',
        registryEntityCode: this.$route.query.registryEntityCode, //研究
        parentSectionCode: '', //访视
        sectionCode: '', //表单
        parentDdCode: '', //作为条件的变量
        parentValue: '', // 条件值
        operator: '', //操作符
        ddCode: '', //标识在这个字段上做了条件显示
        type: '1', // 目前只有 1 。'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
        sectionList: [], //选择访视
        formList: [], //选择表单
        variableList: [], //选择变量
        controlType: '',
      },
      calculateMain: {
        pkId: '',
        registryEntityCode: this.$route.query.registryEntityCode,
        sectionCode: this.$route.query.sectionCode,
        code: '',
        ddCode: '',
        desc: '', //表达式中相关 item的数组, json字符串格式
        calculate: '', // 表单录入解析后的表达式
        calculateDesc: '', //表单设计 表达式
        ruleStructure: '',
        calculateMain: 0,
        status: '',
        type: 1, //1 数字 2 日期
        isEdit: 0,
      },
      researchName: this.$route.query.name,
      formSelectionType: this.$route.query.selectionType,
      parentCode: this.$route.query.parentCode,
      registryEntityCode: this.$route.query.registryEntityCode,
      sectionCode: this.$route.query.sectionCode,
      sectionType: this.$route.query.selectionType,
      repeatNum: this.$route.query.repeatNum,
      allowAdd: this.$route.query.allowAdd,
      isEpro: this.$route.query.isEpro,
      category: this.$route.query.category,
      formDetail: null,
      sureFormList: [],
      activeName: '1',
      options: regionDataPlus,
      dialogVisible: false,
      dialogVisibleList: false,
      expArr: [],
      preClickItemDdcode: '',
      activeNames: ['1'],
      currentFormItem: {
        calculateMain: {
          pkId: '',
          registryEntityCode: this.$route.query.registryEntityCode,
          sectionCode: this.$route.query.sectionCode,
          code: '',
          ddCode: '',
          desc: '',
          calculate: '',
          calculateDesc: '',
          ruleStructure: '',
          calculateMain: 0, //是否核查 0不核查, 1 核查
          status: '',
          type: 1, //1 数字 2 日期
          isEdit: 0,
        },
        isLogicCk: 0, //逻辑核查 0否,1是
        scopeValid: 0, //范围核查,1是,0否
        allowDecimal: 0, //允许小数,1是 0 否
        decimalDigits: 2, //小数位数
        scopeRange_up: 0,
        scopeRange_down: 0,
        scopeRange: '', //数值范围
        dataType: 0, //是否只读 1是,0否
        sectionCode: '',
        registryEntityCode: '',
        isCalculate: 0, // 0输入,1 计算
        isCondition: 0, //是否作为 显示条件 暂时没使用
        isImExport: 0, //1是否必填 1必填 0非必填
        ddInputGuide: '', //填写指南
        ddCode: '',
        ddDesc: '',
        numConfig: '',
        ddDisplayNameEn: '',
        groupCode: '',
        groupRepeat: '',
        pkId: null,
        name: '',
        ddName: '',
        unitOfMeasure: '',
        value: '',
        displayOrder: null,
        visible: '', //是否显示 1显示 0隐藏
        isNeed: 1,
        rule: 'all',
        controlType: '',
        desensitization: 'bypassed', //脱敏设置
        type: 'private',
        status: 1,
        optionNames: '',
        visibleConditionList: [{
          pkId: '',
          registryEntityCode: this.$route.query.registryEntityCode, //研究
          parentSectionCode: '', //访视
          sectionCode: '', //表单
          parentDdCode: '', //作为条件的变量
          parentValue: '', // 条件值
          operator: '', //操作符
          ddCode: '', //标识在这个字段上做了条件显示
          type: '1', //'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
          sectionList: [], //选择访视
          formList: [], //选择表单
          variableList: [], //选择变量
          controlType: '',
        }],
        itemOptionList: [{
          displayName: '',
          value: '',
          registryEntityCode: this.$route.query.registryEntityCode,
          sectionCode: this.$route.query.sectionCode,
        }],
      },
      currentFormItemIndex: '',
      visibleContidionGuide: "多个值请使用英文逗号(,)分隔",
      visibleConditionRules: {
        parentSectionCode: [{
          required: false,
          message: '请选择访视',
          trigger: 'change,blur'
        }, ],
        sectionCode: [{
          required: false,
          message: '请选择表单',
          trigger: 'change,blur'
        }, ],
        parentDdCode: [{
          required: false,
          message: '请选择变量',
          trigger: 'change,blur'
        }, ],
        operator: [{
          required: false,
          message: '请选择操作符',
          trigger: 'change,blur'
        }, ],
        parentValue: [{
          required: false,
          message: '请设置条件',
          trigger: 'change,blur'
        }, ],
      },
      rules: {
        ddName: [{
            required: true,
            message: '请设置问题标题',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
          }
        ],
        ddDisplayNameEn: [{
            required: true,
            message: '请填写变量名',
            trigger: 'blur'
          },
          {
            min: 1,
            max: 255,
            message: '长度在 1 到 255 个字符',
            trigger: 'blur'
          },
          {
            pattern: /^[0-9a-zA-Z]{1,255}$/,
            message: '只允许输入英文和数字',
            trigger: 'change,blur'
          }
        ],
        unitOfMeasure: [{
          min: 0,
          max: 25,
          message: '长度在 1 到 25个字符',
          trigger: 'change,blur'
        }],
        ddDesc: [{
          min: 0,
          max: 500,
          message: '长度在 1 到 500个字符',
          trigger: 'change,blur'
        }],
        ddInputGuide: [{
          min: 0,
          max: 500,
          message: '长度在 1 到 500个字符',
          trigger: 'change,blur'
        }],
        CBValue: [{
          min: 0,
          max: 255,
          message: '长度在 1 到 500个字符',
          trigger: 'change,blur'
        }],
        ADSELValue: [{
          min: 0,
          max: 255,
          message: '长度在 1 到 500个字符',
          trigger: 'change,blur'
        }],
        value: [{
          min: 0,
          max: 255,
          message: '长度在 1 到 500个字符',
          trigger: 'change,blur'
        }],
        decimalDigits: [{
            required: false,
            message: '',
            trigger: 'blur'
          },
          {
            pattern: /(^[0-9]{1,18}$)/,
            message: '请输入整数!',
            trigger: 'change,blur'
          }
        ],
        scopeRange_up: [{
            required: false,
            pattern: /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/,
            message: '',
            min:0,
            trigger: 'blur'
          },
          {
            pattern: /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/,
            message: '请输入数字!',
            trigger: 'change,blur'
          }
        ],
        scopeRange_down: [{
          pattern: /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/,
            required: false,
            message: '请输入数字',
            min:0,
            trigger: 'blur'
          },
          {
            pattern: /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/,
            message: '请输入数字!',
            trigger: 'change'
          }
        ],
      },
      formData: {
        registryEntityCode: this.$route.query.registryEntityCode,
        sectionCode: this.$route.query.sectionCode,
        defaultGroupCode: ''
      },
      //组件特殊类型处理
      CBValue: '',
      ADSELValue: '',
      //组标签
      groupTagVisible: false,
      groupTag: '',
      groupTagList: [],
      isShowWaring: false,
      groupTagFormRules: {
        name: [{
            required: true,
            message: '请输入组标签名称',
            trigger: 'change,blur'
          },
          {
            min: 1,
            max: 255,
            message: '长度在 1 到 255个字符',
            trigger: 'change,blur'
          }
        ],
      },
      optionListData: [],
      topDistance: 0,
      leftDistance: 0,
      isShowGroupTip: false,
      optionsForm: {
        search: '',
        optionSetName: '',
        optionSetDdCode: '',
        itemOptionFormList: [{
          selectName: '',
          selectCode: '',
          selectImage: '上传图片',
          selectImagePath: ''
        }]
      },
      getSpirceList:[{val:''}],
      currentGroupForm:{
        visibleConditionList: [{
          pkId: '',
          registryEntityCode: this.$route.query.registryEntityCode, //研究
          parentSectionCode: '', //访视
          sectionCode: '', //表单
          parentDdCode: '', //作为条件的变量
          parentValue: '', // 条件值
          operator: '', //操作符
          ddCode: '', //标识在这个字段上做了条件显示
          type: '1', //'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
          sectionList: [], //选择访视
          formList: [], //选择表单
          variableList: [], //选择变量
          controlType: '',
        }],
      },
      ntxRule: {
          
      }
    };
  },
  created() {
    this.formData = this.$route.query;
    //获取表单详情
    this.getFormDetail();
    //获取组标签
    this.getGrouptagData();
    this.getOptionList('0');
  },
  mounted() {

    this.initSelector();

    //label名字过长处理
    // 视口变化时改变表单label宽度，控制省略号是否显示
    let col = this.$refs.colWidth.$el;
    window.onresize = () => {
      let oNode = col.querySelectorAll('.el-form-item__label');
      let oOption = col.querySelectorAll('.el-radio__label,.el-checkbox__label');
      Array.from(oNode).forEach((node) => {
        node.style.width = col.offsetWidth - 190 + 'px';
      })
      Array.from(oOption).forEach((node) => {
        node.style.maxWidth = col.offsetWidth - 120 + 'px';
      })
    }
  },
  methods: {
    initGroupTagForm(){
      this.groupTagForm = {
          groupName: '未定义组',
          isRepeat: false,
          groupCount: 1,
          show:1,
          allow:1
        }
    },
    setGroupTagFormReplaceNum(v){
      this.$set(this.groupTagForm, 'replaceNum', v)
    },
    sgetSpirceList(v=''){
      let arr = [];let length;
      if (!v) {  
        length =Array.isArray(v)?v.length: JSON.parse(localStorage.getItem('groupCount'))||1 
      }
      else { 
        if (Array.isArray(v)) {
          length = v.length
        }else {
          if (v && !Array.isArray(JSON.parse(v))) {
            length =1
          }else {
            length = Array.isArray(v)?v.length: JSON.parse(v).length 
          }
        }
        
      }
      
      let currentFormItem = JSON.parse(localStorage.getItem('currentFormItem'))
      for (let index = 0; index < length; index++) {
        let element 
        if ( currentFormItem.controlType === 'CB') {

          if (!v) { element = {val:[]};
          }else { element = {val:[... JSON.parse(v)[index]]};}

          arr.push(element)
        }else {

          if (!v) { element = {val:''};}
          else { element = {val:JSON.parse(v)[index]};}
          if (v && !Array.isArray(JSON.parse(v))) {
             element = {val:v}
          }

          arr.push(element)
        }
      }
      return arr
    },
    initSureFormList(){
       this.$set(this.defaultSourceForm,'sureFormList',this.rsureFormList())
    },
    rsureFormList(){
      let arr = []
      let item = {}

      let currentFormItem = JSON.parse(localStorage.getItem('currentFormItem'))
      switch (currentFormItem.controlType) {
        case 'RB':
          item.val = []
          item.itemOptionList = [... currentFormItem.itemOptionList]
          item.controlType = currentFormItem.controlType
          break;
        case 'SEL_SIG':
          item.val = ''
          item.itemOptionList = [... currentFormItem.itemOptionList]
          item.controlType = currentFormItem.controlType
          break;
        case 'TB':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'LB':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'DP':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'TP':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'DTP':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'TA':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'LABEL':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'SUF':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'ADSEL':
          item.val = ''
          item.controlType = currentFormItem.controlType
          break;
        case 'CB':
          item.val = []
          item.controlType = currentFormItem.controlType
          item.itemOptionList = [... currentFormItem.itemOptionList]
          break;
        case 'NTX':
          item.val = ''
          item.controlType = currentFormItem.controlType
          item.unitOfMeasure = currentFormItem.unitOfMeasure
          break;
      
        default:
          break;
      }
      let length = JSON.parse(localStorage.getItem('groupCount'))||1
      for (let index = 0; index < length; index++) {
        arr.push(item)
      }
      return arr
    },
    setCurrentGroupForm(v){
      this.$set(this.currentGroupForm.visibleConditionList[0],'formList',v)
      // this.$set(this.currentGroupForm.visibleConditionList[0],'sectionCode','')
      // this.$set(this.currentGroupForm.visibleConditionList[0],'parentDdCode', '')
      // this.$set(this.currentGroupForm.visibleConditionList[0],'operator','')
      // this.$set(this.currentGroupForm.visibleConditionList[0],'parentValue','')
    },
    setCurrentGroupFormList(v){
       this.currentGroupForm.visibleConditionList[0].formList = v
    },
    setCurrentGroupVisibleConditionList({d,code}){
      this.$set(this.currentGroupForm.visibleConditionList[0],'variableList',d.filter(i => (i.ddCode != code && i.controlType != 'SUF')));
    },
    setCurrentGroupVisibleVariableList(v){
      this.currentGroupForm.visibleConditionList[0].variableList = v
    },
    onChangeReplaceNum(v) {
      if (parseInt(v) > 40) {
        this.$set(this.groupTagForm, 'replaceNum', 40)
        this.isShowWaring = true
      } else {
        this.isShowWaring = false
      }
    },
    onBlurReplaceNum() {
      this.isShowWaring = false
    },
    handleIconClick(v) {
      this.initFroupTagForm()
      this.initCurrentGroupForm()
      this.isShowGroupSelect = false
      this.dialogVisibleGroup = true
    },
    initCurrentGroupForm(){
      this.currentGroupForm = {
        visibleConditionList: [{
          pkId: '',
          registryEntityCode: this.$route.query.registryEntityCode, //研究
          parentSectionCode: '', //访视
          sectionCode: '', //表单
          parentDdCode: '', //作为条件的变量
          parentValue: '', // 条件值
          operator: '', //操作符
          ddCode: '', //标识在这个字段上做了条件显示
          type: '1', //'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
          sectionList: [], //选择访视
          formList: [], //选择表单
          variableList: [], //选择变量
          controlType: '',
        }],
      }
    },
    onDelGroupChild({code,cb}){
      delGroupChild({code:code,newGroupCode: this.groupTagList[0].groupCode}).then((res)=>{

        if (res.code == 200 ) {
          this.$message({
            type: 'success',
            message:res.data
          })
          
          cb()
        }
      }).catch((err)=>{
        console.log(err);
      })
    },
    /**
     * onEditGroup 编辑/删除组标签
     * param v：选择的组标签 type：1、编辑，0:删除
    */
    onEditGroup({v,type}) {
      if (type) {
        this.dialogVisibleGroup = true
        this.isShowGroupSelect = false
        this.groupCodeGet = {
          groupCode: v.groupCode,
          pkId: v.pkId,
          isRepeat:v.isRepeat,
          groupName:v.groupName,
          groupCount:v.groupCount,
          allow:v.allow,
          show:v.isShow,
        }
        this.editGroupLoading = true
        this.onSelGroupChild({ code: v.groupCode,versionCode:JSON.parse(localStorage.getItem('versionCode'))})
      } else {

        this.onDelGroupChild({code:v.groupCode,cb:()=>{
          this.getGrouptagData(()=>{
            this.groupTagList = this.groupTagList.filter((e)=>{
              return e.groupCode != v.groupCode
            })
            if (v.groupName === this.currentFormItem.groupName) {
              this.currentFormItem.groupName = ''
            }
          })
          this.isShowGroupSelect = false
        }})
        
      }
    },
    onSelGroupChild(v){
      selGroupChild({... v}).then((res)=>{
        if (res.code == 200) {
          this.groupTagForm = {
            allow: res.data.allow,
            groupCount: res.data.groupCount,
            groupCode: res.data.groupCode,
            groupName: res.data.groupName,
            isRepeat: parseInt(res.data.isRepeat) ? true: false,
            operator: res.data.childV?res.data.childV.operator:'',
            parentDdCode: res.data.childV?res.data.childV.parentDdCode:'',
            parentSectionCode: res.data.childV? res.data.childV.parentSectionCode:'',
            parentValue: res.data.childV? res.data.childV.parentValue : '',
            sectionCode: res.data.childV? res.data.childV.sectionCode:'',
            show: typeof res.data.isShow == 'object' ? 1 :res.data.isShow,
            pkId: res.data.pkId
          }
          this.currentGroupForm = {
            visibleConditionList: [{
              pkId: res.data.pkId,
              operator:res.data.childV? res.data.childV.operator:'',//操作符
              registryEntityCode: this.$route.query.registryEntityCode, //研究
              parentSectionCode: res.data.childV? res.data.childV.parentSectionCode:'', //访视
              sectionCode: res.data.childV? res.data.childV.sectionCode:'', //表单
              parentDdCode: res.data.childV?res.data.childV.parentDdCode:'', //作为条件的变量
              parentValue: res.data.childV? res.data.childV.parentValue : '', // 条件值
              ddCode: '', //标识在这个字段上做了条件显示
              type: '1', //'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
              sectionList: res.data.childV?res.data.childV.sectionList:[], //选择访视
              formList: res.data.childV?res.data.childV.formList:[], //选择表单
              variableList: res.data.childV?res.data.childV.variableList:[], //选择变量
              controlType: '',
            }],
          }
        }
        this.editGroupLoading = false
      }).catch((error)=>{
        this.editGroupLoading = false
      })
    },
    //选择组标签
    onSelectGroupItem(v) {
      this.$set(this.currentFormItem, 'groupName', v.groupName)
      this.isShowGroupSelect = false
      this.isShowGroupTip = true
      this.formatterGroupCode()
      localStorage.setItem('groupCount',JSON.stringify(v.groupCount))
      this.$set(this.defaultSourceForm,'sureFormList',this.rsureFormList())
      this.getSpirceList = this.sgetSpirceList()
    },
    formatterGroupCode(){
      let idx = 0
        this.groupTagList.map((v,i)=>{
          if (v.groupName.split('(')[0] == this.currentFormItem.groupName || v.groupName == this.currentFormItem.groupName) {
              idx = i
          }
        })
        this.currentFormItem.groupCode = this.groupTagList[idx].groupCode;
    },
    popoverShowGroup() {},
    datasourceChange(v) {
      this.$set(this.defaultSourceForm,'sureFormList',this.rsureFormList())
        if (v == 2) {
          if (this.$route.query.repeatNum == 0) {
            // this.currentFormItem.isCalculate = 0;
            // this.$errInfo('当前表单已经设置为不支持默认值!', 'warning')
          } else {
            this.$nextTick(() => {
              if (this.$refs['multiDefaultValue'])

                this.$refs['multiDefaultValue'].init(this.currentFormItem.value)
            })
          }
        } else {
          this.currentFormItem.value = ''
        }

        if (v == 1) {
          this.currentFormItem.dataType = 1
        }
    },
    initSelector() {
      this.selector = SelectorDefine().FormEdit;
    },
    /* 选项集的函数 start*/
    openOptionsList() {
      this.dialogVisibleList = true;
    },
    //上传图片
    upload(fileObj) {
      const index = fileObj.data.optionIndex;
      const formData = new FormData()
      formData.append('file', fileObj.file)
      formData.append('type', fileObj.file.type)
      fileUploadOptionImage(formData).then(r => {
        if (r.code == '200') {
          const imageName = r.data.url.substr(r.data.url.lastIndexOf("-") + 1);
          this.$set(this.optionsForm.itemOptionFormList[index], 'selectImage', imageName);
          this.$set(this.optionsForm.itemOptionFormList[index], 'selectImagePath', r.data.url);
        }
      }).catch(e => {})
    },
    //每次显示是都重新进行一次数据的初始化
    popoverShow() {
      this.optionsForm = {
        search: '',
        optionSetName: '',
        optionSetDdCode: '',
        itemOptionFormList: [{
          selectName: '',
          selectCode: '',
          selectImage: '上传图片',
          selectImagePath: ''
        }]
      }
      this.currentFormItem.optionNames = '';
      //初始化显示清除表单校验
      this.clearFormVaildate('optionsForm');
    },
    //查询研究下的选项集
    getOptionList(type) {
      let params = {
        researchCode: this.registryEntityCode,
        optionSetName: ''
      };
      if (type == '1') {
        params.optionSetName = this.optionsForm.search
      }
      getOptionSet(params).then(r => {
        if (r.code == 200) {
          this.optionListData = r.data;
          if (type == '1') {
            // this.currentFormItem.optionNames = this.optionListData[this.optionListData.length-1].optionSetName
            // this.optionsForm.optionSetDdCode = this.optionListData[this.optionListData.length-1].ddCode;
            this.selectOption(this.optionListData.length - 1, this.optionListData[this.optionListData.length - 1])
          }
        }
      }).catch(e => {})

    },
    //查询研究下的指定选项集
    deleteOptionList(index, row) {
      const params = {
        researchCode: this.registryEntityCode,
        optionSetName: row.optionSetName
      };
      deleteOptionSet(params).then(r => {
        if (r.code == '200') {
          if (this.optionsForm.search != '') {
            this.getOptionList('1');
          } else {
            this.getOptionList('0');
          }
        }
      }).catch(e => {})
    },
    //相集合里面添加一个新的选项集
    optionsItem() {
      this.optionsForm.itemOptionFormList.push({
        selectName: '',
        selectCode: '',
        selectImage: '上传图片',
        selectImagePath: ''
      })
    },
    //删除集合里面的选项集
    optionsDeleteItem(index) {
      if (this.optionsForm.itemOptionFormList.length > 1) {
        this.optionsForm.itemOptionFormList.splice(index, 1);
      }
    },
    //保存选项集
    submitSaveOptionSet(optionsForm) {
      //表单校验
      debugger
      this.$refs[optionsForm].validate((valid) => {
        if (valid) {
          let paramList = new Array();
          this.optionsForm.itemOptionFormList.forEach((item, index, array) => {
            let itemStr = new Array()
            itemStr[0] = item.selectName
            itemStr[1] = item.selectCode
            if (item.selectImagePath != '') {
              itemStr[2] = item.selectImagePath
            }
            paramList.push(itemStr.join(','))
          })
          let params = {
            researchCode: this.registryEntityCode,
            optionSetName: this.optionsForm.optionSetName,
            str: paramList
          };
          this.currentFormItem.optionNames = params.optionName
          this.btnLoading = true
          try {
            saveOptionSet(params).then(r => {
              if (r.code == '200') {
                this.getOptionList('1');
                //关闭添加弹框
                // this.$refs['popover-1'].doClose();
                this.dialogVisibleList = false
              }
              this.btnLoading = false;
            }).catch(e => {
              this.btnLoading = false;
              // console.log("-----添加选项集失败-----" + JSON.stringify(params))
            })
          } finally {
            //                            this.btnLoading = false;
          }
        } else {
          return false;
        }
      });
    },
    //选择研究下的选项集
    selectOption(index, row) {
      // console.log("---optionName: ", row);
      this.optionsForm.optionSetName = row.optionSetName;
      this.optionsForm.optionSetDdCode = row.ddCode;
      this.currentFormItem.optionNames = row.optionSetName;
      //先清空itemOptionList 里面的数据 然后在进行push的操作
      this.currentFormItem.itemOptionList = []
      let optionValue = row.optionValue
      let strOptionValue = optionValue.substring(optionValue.indexOf('(') + 1, optionValue.lastIndexOf(')'))
      strOptionValue.split(",").forEach(item => {
        let oneOption = item.split("=")
        this.currentFormItem.itemOptionList.push({
          displayName: oneOption[1],
          value: oneOption[0],
          registryEntityCode: this.$route.query.registryEntityCode,
          sectionCode: this.$route.query.sectionCode,
        })
      })
      this.$set(this.sureFormList[this.current], 'itemOptionList', this.currentFormItem.itemOptionList)
      
      this.currentItemNameList.itemOptionList = this.currentFormItem.itemOptionList
      this.currentFormItem.itemOptionList = this.currentFormItem.itemOptionList
      localStorage.setItem('currentFormItem',JSON.stringify(this.currentFormItem))
      this.$set(this.defaultSourceForm,'sureFormList',this.rsureFormList())
      this.getSpirceList = this.sgetSpirceList()
      this.dialogVisibleList = false;
    },
    /* 选项集的函数 end*/

    onOpenCalculator() {
      this.openCalculator = true
      this.expArr =[]
      this.$nextTick(()=>{
        console.log(this.$refs['logicCalculator']);
        // this.$refs['logicCalculator'].delEle(globalConst.calculatorSymbol.clearBtn)
      })
      // this.$refs['calculator'].delEle(globalConst.calculatorSymbol.clearBtn)
      // this.currentFormItem.calculateMain.desc = '';
    },
    cancelCalculateExp() {
      this.openCalculator = false;
    },
    saveCalculateExp(calExpObj) {
        // console.log('form edit------计算表达式---', exp, 'html', desc, 'itemControlType', itemControlType);
        //录入的时候计算用
        //#eco63516p9n3asat#-#l6c3xs18v1b1l2gf#*Math.sqrt(36)+Math.pow(4, '³'== '²' ? 2 : 3)
        this.currentFormItem.calculateMain.calculate = calExpObj.calculateExp;
        // 回显用
        //(,#eco63516p9n3asat#,-,#l6c3xs18v1b1l2gf#,),*,√￣,36,+,4,³
        this.currentFormItem.calculateMain.calculateDesc = calExpObj.calculateArrJson;
        //显示用 ( n5 - n3 ) * √￣ 36+ 4³
        this.currentFormItem.calculateMain.desc = (this.currentFormItem.ddDisplayNameEn || '') +
            "=" + calExpObj.calculateDisplay || '';
        //计算结果的类型 NTX DP TP DTP
        this.currentFormItem.calculateMain.type = calExpObj.type;
        this.currentFormItem.calculateMain.dateUnit = calExpObj.dateUnit;
        this.openCalculator = false;
    },
    //组标签
    getGrouptagData(cb=()=>{console.log(12345);}) {
      getGrouptag({
        registryEntityCode: this.formData.registryEntityCode
      }).then(res => {
        this.groupTagList = res.data;
        let idx = 0
        this.groupTagList.map((v,i)=>{
          if (v.groupName.split('(')[0] == this.currentFormItem.groupName) {
              idx = i
          }
        })
        
        if (localStorage.getItem('isOnlySave') == 1) {
          this.currentFormItem.groupName = this.groupTagList[idx].groupName;
        }else {
          
        }
        this.currentFormItem.groupCode = this.groupTagList[idx].groupCode;
        this.groupCodeGet = this.groupTagList[idx].groupCode;
        this.currentFormItem.groupRepeat = this.groupTagList[idx].isRepeat;
        this.formData.defaultGroupCode = this.groupTagList[idx].groupCode;
        if (res.code == 200) {
          cb()
        }
      }).catch(e => {

      })
    },
    groupTagShow() {
      this.groupTagForm = this.$options.data.call(this).groupTagForm;
      this.$refs.groupTagForm && this.$refs.groupTagForm.clearValidate();
    },
    initFroupTagForm(){
       this.groupTagForm = {
                groupName: '未定义组',
                isRepeat: false,
                groupCount: 1,
                show:1,
                allow:1,
                operator: '',
                parentDdCode: '',
                parentSectionCode: '',
                parentValue:  '',
                sectionCode:'',
            }
    },
    addGroupLabel({v:v,cb:cb}) {
      
      let sendObj = {... v}
      if (sendObj.isRepeat) {
        sendObj.isRepeat = 1
        sendObj.groupCount = parseInt(sendObj.groupCount)
      } else {
        sendObj.isRepeat = 0
        delete sendObj.groupCount
        delete sendObj.allow
        delete sendObj.show
        delete sendObj.isShow
      }
      
      const postData = {researchCode: this.$route.query.registryEntityCode,... sendObj}
          if (this.groupCodeGet.groupCode &&this.groupCodeGet.groupCode === postData.groupCode) {
            localStorage.setItem('isOnlySave',1)
          }
          addGroupChild(postData).then(res => {
            this.getGrouptagData();
            if(res.code == 200) {
              this.dialogVisibleGroup = false
              
              if (localStorage.getItem('isOnlySave') == 1) {
                this.currentFormItem.groupName = v.groupName
              }else {
                
              }
              this.gbtnLoading = true
              this.initFroupTagForm()
              cb()
              this.$message({
                type:'success',
                message:res.msg
              })
               this.$set(this.defaultSourceForm,'sureFormList',this.rsureFormList())
               this.getSpirceList = this.sgetSpirceList()
            }else{
              this.$message({
                  type:'error',
                  message:res.msg
              })
            }
            this.gbtnLoading = false
          }).catch(e => {
            this.gbtnLoading = false
          })
    },
    getSectionList(e,index){
       const params = {
        registryEntityCode: this.formData.registryEntityCode,
        versionCode: JSON.parse(localStorage.getItem('versionCode'))
      };
      getSection(params).then(r => {
        this.currentFormItem.visibleConditionList[0].sectionList = r.data.data;
      }).catch(e => {
        // this.currentFormItem.visibleConditionList[0].sectionList = [];
      })
    },
    //条件显示 获取访视
    getVisibleFormList(index){
      let params = {
        registryEntityCode: this.formData.registryEntityCode,
        parentCode: this.currentFormItem.visibleConditionList[0].parentSectionCode,
        sectionCode: this.sectionType ? this.sectionCode : '',
        sectionType: this.sectionType,
      };
      getSectionForm(params).then(r => {
        this.currentFormItem.visibleConditionList[index].formList = r.data.data;
      }).catch(e => {
        this.currentFormItem.visibleConditionList[index].formList = [];
      })
    },
    //条件显示 获取访视下的表单
    sectionChangeAndGetForm(e, index) {
      this.clearVisibleCondition(2, index);
    },
    //条件显示 获取访视下的表单的变量
    getVisibleVariableList(index){
      let params = {
        registryEntityCode: this.formData.registryEntityCode,
        sectionCode: this.currentFormItem.visibleConditionList[0].sectionCode,
        groupRepeat: this.currentFormItem.isRepeat,
      }
      getVariable(params).then(r => {
        let d = r.data.data;
        this.currentFormItem.visibleConditionList[index].variableList =
          d.filter(i => (i.ddCode != this.currentFormItem.ddCode && i.controlType != 'SUF'));
      }).catch(e => {
        this.currentFormItem.visibleConditionList[index].variableList = [];
      })
    },
    formChangeAndGetVariable(e, index, clear = true) {
      this.clearVisibleCondition(1, index);
    },

    variableChange(e, index) {
      this.clearVisibleCondition(0, index);
    },
    visibleOperatorChange(e, index) {
      if (e == '{}' || e == "{/}") {
        this.showGuide = true;
        setTimeout(() => this.showGuide = false, 2000);
      }
    },
    clearVisibleCondition(code, index = 0) {
      let t = this.currentFormItem.visibleConditionList;
      if (!(t && t.length > 0))
        return;
      if (code > 2)
        this.currentFormItem.visibleConditionList[0].parentSectionCode = '';
      if (code > 1) {
        this.currentFormItem.visibleConditionList[0].formList = [];
        this.currentFormItem.visibleConditionList[0].variableList = [];
        this.currentFormItem.visibleConditionList[0].sectionCode = '';
        this.currentFormItem.visibleConditionList[0].parentDdCode = '';
      }
      if (code > 0) {
        this.currentFormItem.visibleConditionList[0].variableList = [];
        this.currentFormItem.visibleConditionList[0].parentDdCode = '';
      }

      this.currentFormItem.visibleConditionList[0].operator = '';
      this.currentFormItem.visibleConditionList[0].parentValue = '';
    },

    getFormDetail() {
      const postData = {
        registryEntityCode: this.$route.query.registryEntityCode,
        sectionCode: this.$route.query.sectionCode,
        sectionType: this.sectionType
      };
      getFormList(postData).then(res => {
        console.log('表单设计页面获取item---', res);
        res.data.forEach((item) => {
          (item.controlType === 'CB' || item.controlType === 'SEL_MUL' || item.controlType === 'ADSEL') && (item.value = !!item.value ? item.value.split(',') : []);
          if (item.controlType === 'SLD') {
            item.value = Number(item.value);
          }
          // this.currentFormItem.isShowSDV = true
          // if (ele.controlType === 'LB') {
          //     this.currentFormItem.isShowSDV = false
          // }
          if (this.calculateItems.indexOf(item.controlType) >= 0 && item.numConfig) {
            let numSetting = JSON.parse(item.numConfig)
            if (item.controlType === 'NTX') {
              item.scopeValid = numSetting.scopeValid;
              item.allowDecimal = numSetting.allowDecimal;
              item.scopeRange_down = parseInt(numSetting.scopeRange_down);
              item.scopeRange_up = parseInt(numSetting.scopeRange_up);
              item.decimalDigits = numSetting.decimalDigits;
              item.isLogicCk = numSetting.isLogicCk || 0;
            } else {
              item.isLogicCk = numSetting.isLogicCk || 0;
            }
          }
          item.value = '';
        });
        this.sureFormList = Object.assign([], res.data);
        setTimeout(this.changeLabelWidth(), 300);
      }).catch(function (error) {

      });
    },
    handleSaveFormItem(code) {

      if (this.currentFormItem.controlType === 'CB' || this.currentFormItem.controlType === 'ADSEL')
        this.currentFormItem.value = isArray(this.currentFormItem.value) ? this.currentFormItem.value.join(',') : this.currentFormItem.value;
      if (this.currentFormItem.itemOptionList) {
        let optionLength = this.currentFormItem.itemOptionList.length
        for (let i = 0; i < optionLength; i++) {
          this.currentFormItem.itemOptionList[i].sort = i + 1;
          this.currentFormItem.itemOptionList[i].value = this.currentFormItem.itemOptionList[i].value || this.currentFormItem.itemOptionList[i].displayName;
        }
      }
      if (this.sectionType == 'Multiple' && this.$refs.multiDefaultValue)

        this.currentFormItem.value = this.$refs.multiDefaultValue.saveMultidefaultValueForm()
      // .then(r => this.currentFormItem.value = r.defaultValue);

      
      this.$refs['currentFormItem'] && this.$refs['currentFormItem'].validate &&this.$refs['currentFormItem'].validate((valid) => {
        if (!valid) {
          
          (!this.currentFormItem.ddName||!this.currentFormItem.ddDisplayNameEn)?this.activeTab = 'variable':this.activeTab = 'value'
        }
        this.$refs['itemOptions'] && this.$refs['itemOptions'].validate((itemOptionsValid) => !itemOptionsValid && (valid = false));
        if (this.currentFormItem.visible == '0')
          this.$refs['currentConditionForms'] && this.$refs['currentConditionForms'].validate((visibleValid) => !visibleValid && (valid = false));
        try {
          if (valid) {
            this.btnLoading = true;
            let params = {
              modelDdStructureParam: this.modelDdStructureParam,
              modelMappingSectionParam: this.modelMappingSectionParam,
              groupCode: this.formSelectionType === 'Single' ? this.currentFormItem.groupCode : '',
              sdv: this.currentFormItem.sdv ? this.currentFormItem.sdv : 0
            };

            if (this.currentFormItem.isCalculate == 1)
              params.calculateParam = {... this.calculateParam,dateUnit:this.currentFormItem.calculateMain.dateUnit}

            if (this.currentFormItem.visible == 0)
              params.visibleParam = this.visibleParam;
              params.optionSetDdCode = this.optionsForm.optionSetDdCode
            if (this.currentFormItem.isCalculate == 2) {
              // if (this.sectionType == 'Multiple') {
              //   //  params.value  = JSON.stringify(this.currentFormItem.value.split('#'))
              // } else {
              //   let arr= this.getSpirceList.map((e)=>{
              //     return e.val
              //   })
              //   params.value  = JSON.stringify(arr)
              //   console.log('params ==== ',params);
              //   this.getSpirceList = this.sgetSpirceList(params.value)
              // }
               let arr= this.getSpirceList.map((e)=>{
                  return e.val
                })
                params.value  = JSON.stringify(arr)
                console.log('params ==== ',params);
                this.getSpirceList = this.sgetSpirceList(params.value)
            }
           
            // console.log("修改 item", params);
            if (code == 'save') {
              this.dialogVisible = true;
              addFormItem({
                ...params,
                versionCode: JSON.parse(localStorage.getItem('versionCode'))
              }).then(res => {
                this.$set(this.currentFormItem, 'ddCode', res.data);
                if (this.currentFormItem.controlType === 'CB')
                  this.$set(this.currentFormItem, 'value', []);
                  this.btnLoading = false;
                  this.$set(this.currentFormItem, 'optionSetDdCode', params.optionSetDdCode);
                  this.$errInfo(res.msg, 'success');
                  this.SeeItemConfig(this.sureFormList[this.current], this.current)
              })
            }
            if (code == 'edit') {
              updateFormItem({
                ...params,
                versionCode: JSON.parse(localStorage.getItem('versionCode'))
              }).then(res => {
                if (this.currentFormItem.controlType === 'CB' || this.currentFormItem.controlType === 'ADSEL') {
                  this.currentFormItem.value = this.currentFormItem.value.split(',');
                }
                this.$set(this.currentFormItem, 'optionSetDdCode', params.optionSetDdCode);
                this.$set(this.sureFormList[this.current], 'optionSetDdCode', params.optionSetDdCode)
                this.$errInfo(res.msg, 'success');
                this.SeeItemConfig(this.sureFormList[this.current], this.current)
                this.optionListData.map((ele, index) => {
                  // if (ele.ddCode === this.currentFormItem.optionSetDdCode) {
                  //   this.selectOption(index, ele)
                  // }
                })
                // if (res.code == 200) {
                //     this.getFormDetail()
                // }
              })
            }
          }
        } finally {
          setTimeout(() => this.btnLoading = false, 2000);
        }
      });
    },

    /*
    删除前检查是否被使用,
     */
    ckDelRef(data, index) {
      ckDelRef({
        parentDdCode: data.ddCode
      }).then(r => {
        if (r && r.data > 0) {
          this.$confirm('数据被使用, 继续操作将不可撤销', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.detailItemDelete(data, index);
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        } else {
          if (this.sureFormList.length == 0) {
            return
          }
          this.detailItemDelete(data, index);
        }
      })
    },

     //删除控件
    detailItemDelete(data, index) {

      if (data.ddCode) {
          const postData = {
              registryEntityCode: this.formData.registryEntityCode,
              sectionCode: this.formData.sectionCode,
              ddCode: data.ddCode,
              parentDdCode: data.ddCode
          }
          deleteFormItem(postData).then(res => {
              this.$message({
                  type: 'success',
                  showClose: true,
                  message: res.data,
                  center: true
              });
              
              this.sureFormList.splice(index, 1)
              this.removedItemCodes.push(data.ddCode);
              this.hasRemoved = true;
              // this.getFormDetail();
          })
      } else
        this.sureFormList.splice(index, 1)
        if (index === 0) {
          this.current = 0;
        }else {
          this.current --;
        }
        
        this.SeeItemConfig(this.sureFormList[this.current], this.current)
    },
    //
    saveForm({cb}) {
                let sortData = [];
                let state = true;
                try {

                    this.sureFormList.forEach((item, index) => {
                        if (!item.ddCode) {
                            state = false;
                            throw new Error('endSaveForm')
                        }
                        sortData.push({
                            registryEntityCode: this.formData.registryEntityCode,
                            sectionCode: this.formData.sectionCode,
                            sectionName: this.$route.query.name,
                            ddCode: item.ddCode,
                            ddName: item.ddName,
                            ddDisplayNameEn: item.ddDisplayNameEn,
                            numConfig: item.numConfig,
                            optionSetDdCode: item.optionSetDdCode,
                            sort: index + 1
                        })
                    })
                } catch (e) {
                    e.message === 'endSaveForm' &&
                    this.$message({
                        type: 'warning',
                        showClose: true,
                        message: '所添加表单控件中尚有未保存项，请先保存',
                        center: true
                    });
                } finally {
                    state && sortFormItem(sortData).then(res => {
                        this.isChangeOrder = false
                        if (cb) {
                          setTimeout(()=>{
                            if (res.code === 200) {
                              this.isSaved = true
                              cb(true)
                            }else{
                              this.isSaved = false
                            }
                          },500)
                        }else {
                          this.isSaved = false
                          this.$message({
                              type: 'success',
                              showClose: true,
                              message: res.data,
                              center: true
                          })
                        }
                    })
                }
            },
    clearFormVaildate(formName) {
      if (this.$refs[formName])
        this.$refs[formName].clearValidate();
    },
    getClickEle(ele) {
      let p = {
        registryEntityCode: this.$route.query.registryEntityCode,
        sectionCode: this.$route.query.sectionCode,
        ddCode: ele.ddCode,
        sectionType: this.$route.query.selectionType
      }
      return getCurrentItem(p)
    },
    async SeeItemConfig(ele, index) {

      this.current = index;
      this.activeTab = 'variable'
      if (ele ===undefined) {
        return
      }
      if (ele.controlType === 'LB') {
        this.$set(this.sureFormList[index],'numConfig',ele.numConfig? ele.numConfig:'18px')
      }
      if (this.hasRemoved) {
        this.clearVisibleCondition(2);
        this.hasRemoved = false;
      }
      if (ele.controlType === 'CB' || ele.controlType === 'ADSEL')
        this.CBValue = this.ADSELValue = isArray(ele.value) ? ele.value.join(',') : ele.value;

      // if(ele.controlType==='ADSEL'){
      //     this.ADSELValue = isArray(ele.value) ? ele.value.join(',') : ele.value;
      // }
      if (ele.ddCode) {
        this.currentLoading = true
        await this.getClickEle(ele).then(r => {
          this.currentLoading = false
            this.currentFormItem.calculateMain.desc = ''
          if (r.data) {
            this.currentFormItem.groupCount = r.data.groupCount
            this.$set(this, 'currentFormItem', {...r.data});
            console.log(2222,this.currentFormItem.dataType);
            this.currentFormItem.isShowSDV = true
            if (ele.controlType === 'LB') {
              this.currentFormItem.isShowSDV = false
            }
            if (this.calculateItems.indexOf(this.currentFormItem.controlType) >= 0 &&
              this.currentFormItem.numConfig) {
              let numSetting = JSON.parse(this.currentFormItem.numConfig||'')
              if (this.currentFormItem.controlType === 'NTX') {
                // this.currentFormItem.scopeValid = numSetting.scopeValid;
                // this.currentFormItem.allowDecimal = numSetting.allowDecimal;
                // this.currentFormItem.scopeRange_down = numSetting.scopeRange_down;
                // this.currentFormItem.scopeRange_up = numSetting.scopeRange_up;
                // this.currentFormItem.decimalDigits = numSetting.decimalDigits;
                this.$set(this.currentFormItem, 'scopeValid', numSetting.scopeValid)
                this.$set(this.currentFormItem, 'allowDecimal', numSetting.allowDecimal)
                this.$set(this.currentFormItem, 'decimalDigits', numSetting.decimalDigits)
                this.$set(this.currentFormItem, 'scopeRange_down', parseInt(numSetting.scopeRange_down))
                this.$set(this.currentFormItem, 'scopeRange_up', parseInt(numSetting.scopeRange_up))
                this.currentFormItem.isLogicCk = numSetting.isLogicCk || 0;
              } else {
                this.currentFormItem.isLogicCk = numSetting.isLogicCk || 0;
                this.currentFormItem.numConfig = ''
              }
            }

          } else {
            this.currentFormItem = ele;
            console.log(3333,this.currentFormItem);
          }
        }).catch(e => {
          this.currentLoading = false
          this.currentFormItem = ele;
          console.log(4444,this.currentFormItem);
        })
      } else {
        this.currentLoading = false
        this.currentFormItem = ele;
        console.log(5555,this.currentFormItem);
      }
      this.$set(this.currentFormItem, 'sdv', this.currentFormItem.sdv ? this.currentFormItem.sdv : '0')
      if (this.$refs['multiDefaultValue']) {
        this.$refs['multiDefaultValue'].init(this.currentFormItem.value);
        this.currentFormItem.value = '';
      }
      let gArr = this.groupTagList.filter(s => s.groupCode == this.currentFormItem.groupCode);
      //重复组的标签不涉及显示隐藏
     
      //选项集数据设置
      this.itemClickSetOptions();
      //条件显示相关设置
      this.itemClickSetVisible();
      //计算相关设置
      this.itemClickSetCalculate();

      //重复表单默认值初始化相关
      localStorage.setItem('currentFormItem',JSON.stringify(this.currentFormItem))
      const count = this.$route.query.selectionType=='Single'?JSON.stringify(this.currentFormItem.groupCount || 1):this.$route.query.repeatNum
      localStorage.setItem('groupCount', count)
      this.$set(this.currentFormItem, 'controlType',this.currentFormItem.controlType)
      this.$set(this.currentFormItem, 'groupName',this.currentFormItem.groupName || '未定义组')
      this.$set(this.defaultSourceForm,'sureFormList',this.rsureFormList())

      this.getSpirceList = this.sgetSpirceList(this.currentFormItem.value)
      this.defaultName = this.currentFormItem.ddName
      this.groupCodeGet = this.currentFormItem.groupCode
      this.currentItemNameList.defaultName = this.currentFormItem.ddName
      this.currentItemNameList.itemOptionList = this.currentFormItem.itemOptionList
      
       this.currentFormItem.showVisible = (this.currentFormItem.isRepeat == undefined ||this.currentFormItem.isRepeat == 0)?1:0
      //处理epro表单
      this.itemClickHandleEpro(this.currentFormItem.dataType);

      //清除表单验证
      this.itemClickClearFormValidition();
      this.currentFormItemIndex = index
      console.log('SeeItemConfig ele:******', index,this.currentFormItem);
      // this.optionListData.map((ele, index) => {
      //   if (ele.ddCode === this.currentFormItem.optionSetDdCode) {
      //     this.selectOption(index, ele)
      //   }
      // })
    },
    itemClickHandleEpro(dataType) {
      this.currentFormItem.dataType = this.isEpro == 1 ? '1' : dataType;
    },
    itemClickSetOptions() {
      this.$set(this.optionsForm, 'optionSetDdCode', '');
      this.$set(this.optionsForm, 'optionSetName', '');
      this.$set(this.currentFormItem, 'optionNames', '');
      if (this.currentFormItem.controlType === 'CB' || this.currentFormItem.controlType === 'SEL_SIG' || this.currentFormItem.controlType === 'RB') {
        if (this.currentFormItem.optionSetDdCode != null) {
          this.optionListData.forEach((item, index, array) => {
            if (this.currentFormItem.optionSetDdCode == item.ddCode) {
              this.optionsForm.optionSetDdCode = this.currentFormItem.optionSetDdCode
              this.optionsForm.optionSetName = item.optionSetName
              this.currentFormItem.optionNames = item.optionSetName
            }
          })
        }
      }
    },
    itemClickSetVisible() {
      if (this.currentFormItem.visible == 0) {
        if (this.currentFormItem.visibleConditionList.length > 0) {
          this.currentFormItem.visibleConditionList = this.currentFormItem.visibleConditionList.slice(0, 1);
          for (let i = 0; i < this.currentFormItem.visibleConditionList.length; i++) {
            const condition = this.currentFormItem.visibleConditionList[i];

            if (this.currentFormItem.visibleConditionList[i].variableList &&
              this.currentFormItem.visibleConditionList[i].variableList.length > 0)
              this.currentFormItem.visibleConditionList[i].variableList =
              this.currentFormItem.visibleConditionList[i].variableList
              .filter(i => (i.ddCode != this.currentFormItem.ddCode));

            if (this.removedItemCodes.indexOf(condition.parentDdCode) > -1)
              this.clearVisibleCondition(3, i);

          }
        } else {
          this.currentFormItem.visibleConditionList.push(this.visibleCondition);
        }
      } else if (this.currentFormItem.visibleConditionList.length == 0 || this.currentFormItem.visible == 1) {
        this.currentFormItem.visibleConditionList = [this.visibleCondition];
        this.clearVisibleCondition(3);
      }
    },
    itemClickSetCalculate() {
      if (this.currentFormItem.calculateMain == null)
        this.currentFormItem.calculateMain = this.calculateMain;

    },
    itemClickClearFormValidition() {
      let formNames = ['currentFormItem', 'currentConditionForms', 'itemOptions'];
      this.$nextTick(() => formNames.forEach(ii => this.clearFormVaildate(ii)));
    },
    log(e) {
      this.isChangeOrder = true
      let newIndex = e.added ?e.added.newIndex :e.moved.newIndex
      console.log(newIndex);
      localStorage.setItem('currentFormItem',this.sureFormList[newIndex])
      this.SeeItemConfig(this.sureFormList[newIndex], newIndex)
      this.sureFormList.map((e) => {
        if (e.controlType === "LB") {
          e.isShowSDV = false
        } else {
          e.isShowSDV = true
        }
      })
      // this.changeLabelWidth();
    },
    optionsDelete(index) {
      this.currentFormItem.itemOptionList.splice(index, 1);
      this.changeLabelWidth();
    },
    optionsAdd() {
      this.currentFormItem.itemOptionList.push({
        displayName: '',
        value: '',
        registryEntityCode: this.$route.query.registryEntityCode,
        sectionCode: this.$route.query.sectionCode,
      })
    },
    //CB多选框值变化时
    CBChange(value) {
      this.CBValue = !!value ? value.join(',') : '';
    },
    ADSELChange(value) {
      this.ADSELValue = !!value ? value.join(',') : '';
    },
    //首次改变表单label宽度，控制省略号是否显示
    changeLabelWidth() {
      if (this.sureFormList.length) {
        let index = this.current > -1 ? this.current : 0
        this.SeeItemConfig(this.sureFormList[index], index)
      }
      let col = this.$refs.colWidth.$el;
      let oNode = col.querySelectorAll('.el-form-item__label');
      let oOption = col.querySelectorAll('.el-radio__label,.el-checkbox__label');
      Array.from(oNode).forEach((node) => node.style.width = col.offsetWidth - 190 + 'px');
      Array.from(oOption).forEach((node) => node.style.maxWidth = col.offsetWidth - 120 + 'px');
    },
  },
   beforeRouteLeave(to, from, next) {
     if (localStorage.getItem('invalid')&&localStorage.getItem('invalid')==1) {
       next()
       return
     }
    let state = false;
    let isSaved = this.isSaved
    console.log(isSaved);
    try {
        this.sureFormList.forEach((item, index) => {
            if (!item.ddCode) {
                state = false
                throw new Error('endRouteLeave');
            }
        })
        if (this.sureFormList.every((e)=>e.ddCode)) {
          this.saveForm({cb:function(v){
            state = v
           v &&  next()
          }})
        }
    } catch (e) {
        if (e.message === 'endRouteLeave') {
            setTimeout(() => {
                this.$confirm('表单中尚有控件未保存，是否确认离开？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                  this.sureFormList = this.sureFormList.filter((item)=>{
                    return item.ddCode
                  })
                  this.saveForm({cb:function(v){
                    state = v
                      v&&  next()
                  }})
                  
                } 
                ).catch(() => next(false));
            })
        }
    } finally {
        state && next()
    }
  },
  computed: {
    ...mapState(['isEdit']),
    canEditForm() {
      if (this.isEdit != 0) {
        return true
      }
    },
    showDesignItem(item) {
      return ((item) => {
        return {
          ddName: '',
          textLabel: item.controlType == 'LB', //级别
          ddDisplayNameEn: item.controlType != 'LB',
          unitOfMeasure: item.controlType == 'NTX',
          ddDesc: item.controlType != 'LB',
          groupTag: this.formSelectionType === 'Single' && item.controlType != 'LB',
          visible: item.controlType != 'LB',
          ddInputGuide: item.controlType != 'LB',
          visibleSetting: item.visible == '0',

          tabPaneValue: item.controlType != 'LB',

          allowDecimal: item.controlType == 'NTX',
          decimalDigits: item.controlType == 'NTX' && item.allowDecimal == 1,
          scopeValid: item.controlType == 'NTX',
          scopeRange: item.controlType == 'NTX' && item.scopeValid,
          selectorSet: item.controlType == 'RB' || item.controlType == 'SEL_SIG' || item.controlType == 'CB',
          dataSource: item.controlType != 'LB' && item.controlType != 'SUF',
          calculateRule: item.isCalculate == 1 || (item.calculateMain && item.calculateMain.desc),
          isImExport: '',
          defaultValue: '',
          dataType: '',
          isCalculate: '',
        }
      });
    },

    ddConfiguration() {
      if (this.currentFormItem.controlType == 'NTX')
        return JSON.stringify({
          allowDecimal: this.currentFormItem.allowDecimal,
          scopeValid: this.currentFormItem.scopeValid,
          decimalDigits: this.currentFormItem.decimalDigits,
          scopeRange_down: parseInt(this.currentFormItem.scopeRange_down),
          scopeRange_up: parseInt(this.currentFormItem.scopeRange_up),
          isLogicCk: this.currentFormItem.isLogicCk,
        });
      else if (this.currentFormItem.controlType == 'TP' ||
        this.currentFormItem.controlType == 'DP' ||
        this.currentFormItem.controlType == 'DTP') {
        return JSON.stringify({
          isLogicCk: this.currentFormItem.isLogicCk
        })
      } else if (this.currentFormItem.controlType == 'LB') {
        return this.currentFormItem.numConfig||'';
      }
      else
        return '';
    },
    modelDdStructureParam() {
      return {
        ddInputGuide: this.currentFormItem.ddInputGuide,
        dataType: this.currentFormItem.dataType, //是否只读,0 否 1是
        ddCode: this.currentFormItem.ddCode,
        ddName: this.currentFormItem.ddName,
        //当控件为数字时,存储 数字相关的设置
        numConfig: this.ddConfiguration,
        ddDisplayNameEn: this.currentFormItem.ddDisplayNameEn,
        isCalculate: this.currentFormItem.isCalculate,
        isCondition: this.currentFormItem.isCondition,
        isImExport: this.currentFormItem.controlType == 'SUF' || this.currentFormItem.visible == 0 ?
          0 : this.currentFormItem.isImExport,
        displayOrder: this.currentFormItem.id,
        visible: this.currentFormItem.visible,
        controlType: this.currentFormItem.controlType,
        dataPrecision: "",
        unitOfMeasure: this.currentFormItem.unitOfMeasure,
        selectionType: "normal",
        value: this.currentFormItem.value,
        itemOptionList: this.currentFormItem.itemOptionList,
        type: 'private',
        status: 1,
      }
    },
    modelMappingSectionParam() {
      return {
        // ddName: this.currentFormItem.ddName,
        // numConfig: this.ddConfiguration,
        ddDisplayNameEn: this.currentFormItem.ddDisplayNameEn,
        registryEntityCode: this.formData.registryEntityCode,
        sectionCode: this.formData.sectionCode,
        // sectionName: this.$route.query.name,
        sort: this.currentFormItem.id
      }
    },
    visibleParam() {
      return {
        pkId: this.currentFormItem.visibleConditionList[0].pkId,
        registryEntityCode: this.formData.registryEntityCode, //研究
        parentSectionCode: this.currentFormItem.visibleConditionList[0].parentSectionCode, //访视
        sectionCode: this.currentFormItem.visibleConditionList[0].sectionCode, //表单
        parentDdCode: this.currentFormItem.visibleConditionList[0].parentDdCode, //作为条件的变量
        parentValue: this.currentFormItem.visibleConditionList[0].parentValue, // 条件值
        operator: this.currentFormItem.visibleConditionList[0].operator, //操作符
        ddCode: this.currentFormItem.ddCode, //标识在这个字段上做了条件显示
        type: '1' // 暂时没用'1:单字段控制单字段 2：多字段控制单字段 3：单字段控制Section 4:多字段控制Section ',
      }
    },
    calculateParam() {
      return {
        pkId: this.currentFormItem.pkId,
        registryEntityCode: this.$route.query.registryEntityCode, //研究
        sectionCode: this.currentFormItem.sectionCode,
        ddCode: this.currentFormItem.ddCode,
        desc: this.currentFormItem.calculateMain.desc || '',
        type: this.currentFormItem.calculateMain.type,
        calculate: this.currentFormItem.calculateMain.calculate, //以变量名表示的计算表达式
        calculateDesc: this.currentFormItem.calculateMain.calculateDesc, //以ddCode表示的计算表达式
        ruleStructure: '', //暂时没用
        status: 1,
        isEdit: 0,
      }
    }
  },

  watch: {
    getSpirceList:{
      deep:true,
      handler:function(){}
    },
    'currentFormItem': {
      deep: true,
      handler: function (n, o) {
        console.log(n);
        if (this.sureFormList.length) {
          this.$set(this.sureFormList[this.current === -1 ? 0 : this.current], 'ddName', n.ddName)
          this.$set(this.sureFormList[this.current === -1 ? 0 : this.current], 'value', n.value)
        }
        //允许小数,默认小数位数为2
        if (n.allowDecimal == 0) {
          n.decimalDigits = 2;
        } else {
          // if (!n.decimalDigits > 0)
          //   n.decimalDigits = 2;
        }
        if (n.controlType === "NTX") {
          let numConfig = {allowDecimal:n.allowDecimal,scopeValid:n.scopeValid,decimalDigits:n.decimalDigits,scopeRange_down:parseInt(n.scopeRange_down),scopeRange_up: parseInt(n.scopeRange_up),isLogicCk:n.isLogicCk}
          n.numConfig = JSON.stringify(numConfig)
        }
      }
    },
    'sureFormList': {
      deep: true,
      handler() {

      }
    },

    'currentFormItem.visibleConditionList': {
      deep: true,
      // 大小等于只能输入数字,介于只能输入 {数字,数字}, 等号,不等号随意输入
      handler: function (n, o) {
        if (n) {
          n.forEach(e => {
            let o = e.operator;
            if (o == '[]') {
              this.visibleConditionRules.parentValue = this.visibleConditionRules.parentValue.slice(0, 1);
              this.visibleConditionRules.parentValue.push({
                pattern: /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}[,]{1}[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/,
                message: '输入格式: 数字,数字',
                trigger: 'change,blur'
              });
            } else if (o == "{}") {
              this.visibleConditionRules.parentValue = this.visibleConditionRules.parentValue.slice(0, 1);
              console.log('选择了包含于操作,暂时不设置表单验证规则');
            } else if (o == "{/}") {
              this.visibleConditionRules.parentValue = this.visibleConditionRules.parentValue.slice(0, 1);
              console.log('选择了不包含于操作,暂时不设置表单验证规则');
            } else if (o == '==' || o == '!=') {
              this.visibleConditionRules.parentValue = this.visibleConditionRules.parentValue.slice(0, 1);
            } else if (o != '' && o.length > 0) {
              this.visibleConditionRules.parentValue = this.visibleConditionRules.parentValue.slice(0, 1);
              this.visibleConditionRules.parentValue.push({
                pattern: /(^[0-9]{1,18}\.{0,1}[0-9]{0,10}$)/,
                message: '请输入数字!',
                trigger: 'change,blur'
              });
            }
          })
        }
      }
    },

    'currentFormItem.allowDecimal'(n, o) {
      //数值不设置小数时,清空小数位数
      if (n === 0) {
        this.currentFormItem.decimalDigits = 2;
      }
        
    },

    'currentFormItem.scopeValid'(n, o) {
      //数值不设置范围时,清空范围值
      if (n == 0)
        this.currentFormItem.scopeRange_up = 0, this.currentFormItem.scopeRange_down = 0;
    },

    'currentFormItem.isCalculate'(n, o) {
      //非计算时 清空表达式
      if (n == 0) {
        if (this.currentFormItem.calculateMain) {
          this.currentFormItem.calculateMain.desc = '';
          this.currentFormItem.calculateMain.calculateDesc = '';
          this.currentFormItem.calculateMain.calculate = '';
        }

        this.currentFormItem.dataType = '0';
      }
      if (n == 1) this.currentFormItem.dataType = '1';

    },

    'currentFormItem.visible'(n, o) {
      let visibleRules = ['parentSectionCode', 'sectionCode', 'parentDdCode', 'parentValue', 'operator'];
      visibleRules.forEach(i => this.visibleConditionRules[i][0].required = n == '0')
    },
    CBValue(value) {
      // this.currentFormItem.value = !!value ? value.split(/\,|\，/) : [];
    },
    ADSELValue(value) {
      // this.currentFormItem.value = !!value ? value.split(/\,|\，/) : [];
    }
  }
};
</script>

<style lang="scss" scoped>
// .upload-demo{
//     text-align: center;
//     width: 100%;
//     .el-button--primary{
//         width: 72px;
//         text-align: center;
//     }
// }
/deep/ .el-scrollbar__view {
  padding-right: 10px;
}

/deep/ .el-drawer__body {
  padding: 16px;
  box-sizing: border-box;
}

.dropdownList {
  width: 100%;
}

.input-top {
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
}

/deep/ .wrapBtn {
  width: 100%;
  text-align: center;
}

.wrapBtn>.el-button {
  width: 90%;
  /*background:blue;*/
}

/deep/ .el-drawer {
  height: 100%;
  overflow-y: scroll;
}

/deep/ .el-scrollbar__view {
  overflow-x: hidden;
}

.addTitle {
  font-size: 16px;
  color: #0976C4;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
}

.calculate-btn {
  position: relative;
  top: 5px;
  font-size: 25px;
  line-height: 28px;
  margin: 0 10px;
  color: #0d83d8;
}

.form_detail {
  height: 100%;

  .form_content {
    margin-top: 6px;
    position: absolute;
    top: 100px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;

    div {
      box-sizing: border-box;
    }

    .content_left {
      width: 200px;
      height: 100%;
      float: left;
    }

    .content_right {
      width: calc(100% - 200px);
      padding: 0 20px;
      height: 100%;
      float: left;

      .formScroll {
        min-height: 320px;

        .dragArea {
          min-height: 320px;
        }

        .noDate {
          width: 100%;
          height: 300px;
          line-height: 300px;
          background-color: #fff;
          color: #909399;
          text-align: center;

        }

        .list-group-item {
          margin-top: 10px;
          border: 1px solid #eee;
          box-sizing: border-box;
          padding: 10px 20px;
          position: relative;
          cursor: move;

          .top_right {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
          }

          .top_right_two {
            position: absolute;
            right: 50px;
            top: 10px;
          }

          &:first-child {
            margin-top: 0
          }
        }
      }

      .config_body {
        min-width: 340px;
        box-sizing: border-box;

        // border: 1px solid #eee;
        // padding: 20px;
        .optionsBox {
          padding: 0 20px;

          .el-form-item {
            &:nth-child(2) {
              width: 60%;

              .el-input {
                display: inline-block;
                width: 50%;
              }
            }

            display: inline-block;
            width: 30%;
            margin-right: 10px;
            height: 55px;
          }

          button {
            background: #4b89dc;
            color: #fff;
          }

          .optionsTitle {
            margin-bottom: 15px;

            span:nth-child(1) {
              display: inline-block;
              margin-right: 10px;
              width: 30%;

              &:before {
                content: "*";
                color: #f56c6c;
                margin-right: 4px;
              }
            }

            span:nth-child(2) {
              display: inline-block;
              margin: 0 10px;
            }

            .handle {
              cursor: move !important;
            }
          }

          .footer_div {
            margin: 0 12px;
            overflow: hidden;

            .span {
              display: inline-block;
              margin-right: 10px;
              float: right;
            }
          }
        }

        .footer_div {
          margin: 12px 12px;
          overflow: hidden;
          position: fixed;
          right: 0;
          bottom: 0;
          .span {
            display: inline-block;
            margin-right: 10px;
            float: right;
          }
        }
      }
    }
  }
}

.item_focu {
  background: rgba(30, 149, 234, 0.05);
}

.calculate-rule-box {
  width: 200px;
  height: 300px;
  overflow: auto;
}

.add-new-tag {
  cursor: pointer;
}

.replate-wrap {
  display: flex;
  align-items: center;
  height: 28px;

  /deep/ .el-input {
    margin-left: 14px;
    width: 60px;
    margin-right: 14px;
  }

  span {
    font-size: 12px;
    color: #F56C6C;
  }
}

.group-wrap {
  display: flex;
  flex-direction: row;
  width: 60%;
   .item-wrap-g{
     height: 100%;
     overflow-y: scroll;
   }
}
.add-g-btn {
  padding-left: 8px;
}
.pop-wrap-z {
  top: 10% !important;
  bottom: 20px;
}
/deep/ .el-popover  {
  top: 10% !important;
  bottom: 20px;
}
</style>
