<template>
<div class="single-form-wrap">
<el-form ref="form" :model="currentItemNameList" label-width="80px">
  <el-form-item  v-for="(item, index) in defaultSourceForm.sureFormList" :key="index">
    <el-input v-model="getSpirceList[index].val" v-if="item.controlType === 'TB'||item.controlType === 'LB'" :key="item.controlType+index"></el-input>
    <!-- 单选 -->
    <el-radio-group v-if="item.controlType==='RB'" v-model="getSpirceList[index].val" >
      <el-radio v-for="(listItem,index) in item.itemOptionList" :key="index" :label="listItem.displayName">
        {{listItem.displayName}}
      </el-radio>
    </el-radio-group>
    <!-- 多选 -->
    <el-checkbox-group @change="CBChange" v-if="item.controlType==='CB'&&item.itemOptionList.length" v-model="getSpirceList[index].val" >
      <el-checkbox v-for="(listItem,index) in item.itemOptionList" :key="index" :label="listItem.displayName">
        {{listItem.displayName}}
      </el-checkbox>
    </el-checkbox-group>
    <!-- 下拉 -->
    <el-select  v-if="item.controlType==='SEL_SIG' && item.itemOptionList.length" v-model="getSpirceList[index].val"  placeholder="请选择" :key="item.controlType+index" @change ='onChangeSEL_SIG'>
      <el-option v-for="listItem in item.itemOptionList" :key="listItem.pkId" :label="listItem.displayName" :value="listItem.value"></el-option>
    </el-select>
    <!-- 日期选择 -->
    <el-date-picker v-if="item.controlType==='DP'" value-format="yyyy-MM-dd" v-model="getSpirceList[index].val"  type="date" placeholder="选择日期"></el-date-picker>
    <!-- 时间选择 -->
    <el-time-picker placeholder="选择时间" v-if="item.controlType==='TP'" value-format="hh:mm:ss" v-model="getSpirceList[index].val" ></el-time-picker>
    <!-- 时间日期选择 -->
    <el-date-picker v-if="item.controlType==='DTP'" value-format="yyyy-MM-dd HH:mm:ss" v-model="getSpirceList[index].val"  type="datetime" placeholder="选择日期时间"></el-date-picker>
    <!-- 数字 -->
    <el-input type="number" v-if="item.controlType==='NTX'" v-model="getSpirceList[index].val" >
      <template slot="append" v-if="item.unitOfMeasure">
        {{item.unitOfMeasure}}
      </template>
    </el-input>
    <!-- 多行文本 -->
    <el-input v-if="item.controlType==='TA'" v-model="getSpirceList[index].val"  type="textarea" rows="1"></el-input>
    <!-- LABEL -->
    <el-input v-if="item.controlType==='LABEL'" type="textarea" v-model="getSpirceList[index].val" ></el-input>
    <!-- 文件上传 -->
    <el-upload v-if="item.controlType==='SUF'" action="" :show-file-list="false" :auto-upload="false">
      <el-button slot="trigger" size="mini" type="primary">文件选取</el-button>
    </el-upload>
    <!-- 地址选择 -->
    <el-cascader v-if="item.controlType==='ADSEL'" size="mini" style="width:100%" :options="options" v-model="getSpirceList[index].val"  @change="ADSELChange"></el-cascader>
  </el-form-item>
</el-form>
</div>
</template>

<script>
import {
  regionDataPlus,
  CodeToText,
  TextToCode
} from 'element-china-area-data';
export default {
  props:['defaultSourceForm','defaultName','getSpirceList', 'currentItemNameList'],
  data() {
    return {
      options: regionDataPlus,
        form:{
          sureFormList:[]
        },
    }
  },
 
  methods: {
    onChangeSEL_SIG(v){
      console.log(v);
    },
    CBChange() {},
    ADSELChange() {}
  }
}
</script>

<style lang="scss" scoped>
.single-form-wrap {
  border: 1px solid rgba(0, 0, 0, .04);
  border-radius: 4px;
  padding: 20px;
}
</style>
